import React, { Component } from "react";
import "./RadioButton.scss";

import { radioBtn, radioBtnActive } from "../../../assets/svg-icon";

class RadioButton extends Component {
    constructor(props) {
        super(props);
        this.randomId = (((1 + Math.random()) * 0x1000000) | 0).toString(16).substring(1);
    }

    renderLabel() {
        const { label } = this.props;
        return label ? <p className="radio-button__text m-b-xs">{label}</p> : null;
    }

    renderRadioButtons() {

        const { config, dataField= '', value , onChange } = this.props;

        return (
            config ?
                <div>
                    {
                        config.map((radio, index)=>{
                            const id = radio.id || (this.randomId + index);
                            return (
                                <span key={id} className={`radio-button__container inline-flex-center`} >
                                    <input data-field={dataField} onChange={onChange}  id={id} className="radio-button__input" type="radio" name={radio.name} value={radio.value}   checked={value === radio.value}  />
                                    <label htmlFor={id} className="radio-button__label inline-flex-center" >
                                        <img className="radio-button__label-inactive" src={radioBtn} alt="icon"/>
                                        <img className="radio-button__label-active" src={radioBtnActive} alt="icon"/>
                                    </label>
                                    <label htmlFor={id}>{radio.label}</label>
                                </span>
                            )
                        })
                    }

                </div>
                : null
        )
    }

    render() {
        const { classes } = this.props;

        return (
            <fieldset className={`radio-button ${classes}`}>
                {this.renderLabel()}
                {this.renderRadioButtons()}
            </fieldset>
        );
    }
}

export default RadioButton;
