import { combineReducers } from "redux";

import { AUTH_FAIL, SIGN_OUT } from "../actions";
import authReducer from "./authReducers";
import analyticsReducer from "./analyticsReducer";
import contactReducer from "./contactReducers";
import eventReducer from "./eventReducer";
import ssoAuthReducer from "./ssoAuthReducer";

const appReducer = combineReducers({
    auth: authReducer,
    ssoAuth: ssoAuthReducer,
    analytics: analyticsReducer,
    contact: contactReducer,
    events: eventReducer
});

const rootReducer = (state, action) => {
    if (action.type === AUTH_FAIL || action.type === SIGN_OUT) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
