import React, { Component } from "react";
import  './Table.scss';
class HeadTr extends Component {
    render() {
        const {children} = this.props;
        return (
            <thead>
                <tr>
                    {children}
                </tr>
            </thead>
        );
    }
}

export default HeadTr;
