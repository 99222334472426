import React, { Component } from "react";
import "./ToggleButton.scss";

class ToggleButton extends Component {
    constructor(props) {
        super(props);
        this.randomId = (((1 + Math.random()) * 0x1000000) | 0).toString(16).substring(1);
    }

    renderLabel() {
        const { label, id = this.randomId } = this.props;
        return label ? (
            <label htmlFor={id} className="switch__label">
                {label}
            </label>
        ) : null;
    }

    render() {
        const { label, classes, changeHandler, checked, id = this.randomId } = this.props;

        return (
            <fieldset className="field-set">
                {this.renderLabel()}
                <span className={`switch ${classes}`}>
                    <input
                        onChange={changeHandler}
                        id={id}
                        className="switch__input"
                        type="checkbox"
                        name="check"
                        checked={checked}
                    />
                    <label htmlFor={id} className="switch__box">
                        <span className="dot" />
                    </label>
                </span>
            </fieldset>
        );
    }
}

export default ToggleButton;
