import React, { Component } from "react";
import "./LayoutLoading.scss";
import Spinner from "../Spinner/Spinner";

class LayoutLoading extends Component {
    render() {
        const { classes = "green", parentClass = "" } = this.props;
        return (
            <div className={`layout-loading flex-center ${parentClass}`}>
                <Spinner classes={classes} />
            </div>
        );
    }
}

export default LayoutLoading;
