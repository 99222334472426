import React, { Component } from "react";
import { connect } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import { stateToHTML } from "draft-js-export-html";

import "./CreateEvent.scss";
import { addEvent } from "../../../redux/actions";
import DialogModal from "../../Dialog/DialogModal";
import { InputField, RadioButton, RichTextEditor, TextArea } from "../../input";
import { partyPopper } from "../../../assets/svg-icon";
import Button from "../../button/Button/Button";
import ToasterMessage from "../../ToasterMessage/ToasterMessage";
import { validateNonGSMCharacters } from "../../../utils/validateHelperUtils";

const MEMBERSHIP_STATUS = {
    YES: 1,
    NO: 0
};

const IMAGE_TYPES = ["jpg", "jpeg", "png", "gif"];

class CreateEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            eventName: "",
            membershipStatus: MEMBERSHIP_STATUS.YES,
            frame: null,
            frameName: "Upload Frame ...",
            selectedImage: null,
            fromName: "",
            fromEmail: "",
            subjectLine: "",
            editorState: EditorState.createEmpty(),
            smsContent: "",
            isLoading: false,
            emailError: false,
            imageType: null,
            imageError: false,
            smsTemplateError: false
        };
    }

    back() {
        let { step } = this.state;
        step--;
        this.setState({ step });
    }

    eventInfoForm(e) {
        e.preventDefault();
        const { eventName, frame, imageType } = this.state;
        if (eventName.length === 0 || frame === null) {
            return;
        } else if (!IMAGE_TYPES.includes(imageType)) {
            this.setState({ imageError: true });
            return;
        }
        this.setState({ step: 2 });
    }

    emailTemplateFrom(e) {
        e.preventDefault();
        const { fromName, fromEmail, subjectLine, editorState } = this.state;
        const emailContent = convertToRaw(editorState.getCurrentContent()).blocks[0].text;
        let emailRegex = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
        if (fromName.length === 0 || fromEmail.length === 0 || subjectLine.length === 0 || emailContent.length === 0) {
            return;
        } else if (!emailRegex.test(fromEmail)) {
            this.setState({ emailError: true });
            return;
        }
        this.setState({ step: 3 });
    }

    smsTemplateFrom(e) {
        e.preventDefault();
        let {
            smsContent,
            eventName,
            membershipStatus,
            frame,
            fromEmail,
            fromName,
            subjectLine,
            editorState,
            smsTemplateError
        } = this.state;
        const { closeDialog, addEvent } = this.props;
        membershipStatus = Number(membershipStatus);
        const emailContent = stateToHTML(editorState.getCurrentContent());
        if (smsContent.length === 0 || smsTemplateError) {
            return;
        }
        this.setState({ isLoading: true });
        const bodyData = {
            Name: eventName,
            MembershipStatus: membershipStatus,
            FromName: fromName,
            FromEmail: fromEmail,
            Subject: subjectLine,
            EmailContent: emailContent,
            SMSContent: smsContent
        };
        addEvent(bodyData, frame).then(res => {
            if (res) {
                toast(<ToasterMessage text="Your new event is created!" />, { type: "success" });
                this.setState({ isLoading: false });
                closeDialog();
                return;
            }
            this.setState({ isLoading: false });
        });
    }

    handleCloseDialog = () => {
        const { closeDialog } = this.props;
        const { eventName, frame } = this.state;
        if (eventName.length !== 0 || frame !== null) {
            const close = window.confirm("Are you sure? unsave work will be lost.");
            if (close) {
                closeDialog();
            }
            return;
        }
        closeDialog();
    };

    handleInputChange = (e, key) => {
        this.setState({ [key]: e.target.value });
        if (key === "fromEmail" && this.state.emailError) {
            this.setState({ emailError: false });
        }
    };

    handleSmsTemplateChange = e => {
        this.setState({ smsContent: e.target.value });
        if (validateNonGSMCharacters(e.target.value)) {
            this.setState({ smsTemplateError: true });
        } else {
            this.setState({ smsTemplateError: false });
        }
    };

    handleRadioChange = e => {
        this.setState({ membershipStatus: e.target.value });
    };

    handleFileUpload = e => {
        const file = e.target.files[0];
        const type = file.type.split("/").pop();
        let fileName = file.name;
        if (fileName.length > 20) {
            fileName = `${fileName.slice(1, 20)}....${type}`;
        }
        let reader = new FileReader();
        reader.onload = e => {
            let selectedImage = e.target.result;
            this.setState({ frame: file, frameName: fileName, selectedImage, imageType: type, imageError: false });
        };
        reader.readAsDataURL(file);
    };

    handleRichTexboxChange = editorState => {
        this.setState({
            editorState
        });
    };

    renderTopMenu() {
        const { step } = this.state;
        return (
            <div className="create-event-menu flex-center">
                <div className="flex-sb flex-70 create-event-menu-container">
                    <span className="inline-flex-start create-event__tab create-event__tab-1 flex-grow active">
                        <span className="inline-flex-center flex-col">
                            <span className="create-event__tab-icon">&nbsp;</span>
                            <span className="create-event__tab-title m-t-xs">Event Info</span>
                        </span>
                    </span>

                    <span
                        className={`inline-flex-center create-event__tab create-event__tab-2 flex-grow ${
                            step >= 2 ? "active" : ""
                        }`}>
                        <span className="inline-flex-center flex-col">
                            <span className="create-event__tab-icon">&nbsp;</span>
                            <span className="create-event__tab-title m-t-xs">Email Template</span>
                        </span>
                    </span>

                    <span
                        className={`inline-flex-end create-event__tab create-event__tab-3 flex-grow ${
                            step === 3 ? "active" : ""
                        }`}>
                        <span className="inline-flex-center flex-col">
                            <span className="create-event__tab-icon">&nbsp;</span>
                            <span className="create-event__tab-title m-t-xs">SMS Template</span>
                        </span>
                    </span>
                </div>
            </div>
        );
    }

    renderStep1() {
        /*TODO fixed onSubmit issue on back button*/
        const radioButtons = [
            { id: "membership-yes", name: "member", label: "Yes", value: MEMBERSHIP_STATUS.YES },
            { id: "membership-no", name: "member", label: "No", value: MEMBERSHIP_STATUS.NO }
        ];
        let { eventName, membershipStatus, frame, frameName, selectedImage, imageError } = this.state;
        membershipStatus = parseInt(membershipStatus);
        const btnDisable = eventName.length === 0 || frame === null;
        return (
            <form className="flex-100 " onSubmit={e => e.preventDefault()}>
                <div className="form-container fancy-scroll">
                    <div className="form-fields m-a">
                        <h3>Create Your Event</h3>

                        <InputField
                            classes="primary m-t"
                            label="WHAT'S THE NAME OF YOUR EVENT?"
                            placeHolder="Event Name"
                            onChange={e => this.handleInputChange(e, "eventName")}
                            value={eventName}
                        />
                        <RadioButton
                            classes="m-t"
                            label="DO YOU WANT TO ASK FOR MEMBERSHIP STATUS?"
                            config={radioButtons}
                            value={membershipStatus}
                            onChange={this.handleRadioChange}
                        />
                        <InputField
                            type="file"
                            classes="primary m-t"
                            error={imageError}
                            label="UPLOAD A FRAME FOR YOUR EVENT BELOW!"
                            placeHolder={frameName}
                            onChange={this.handleFileUpload}
                            accept="image/gif, image/jpeg, image/png"
                        />
                        <div className="image-preview">{selectedImage && <img src={selectedImage} alt="frame" />}</div>
                    </div>
                </div>
                <div className="form-buttons flex-end-center">
                    <Button
                        type="submit"
                        classes={btnDisable ? "disabled" : "green"}
                        label="Next"
                        clickHandler={this.eventInfoForm.bind(this)}
                    />
                </div>
            </form>
        );
    }

    renderStep2() {
        const { fromName, fromEmail, subjectLine, editorState, emailError } = this.state;
        const emailContent = convertToRaw(editorState.getCurrentContent()).blocks[0].text;
        const btnDisable =
            fromName.length === 0 || fromEmail.length === 0 || subjectLine.length === 0 || emailContent.length === 0;
        return (
            <form className="flex-100" onSubmit={this.emailTemplateFrom.bind(this)}>
                <div className="form-container fancy-scroll">
                    <div className="form-fields m-a">
                        <h3>Email Template</h3>
                        <InputField
                            classes="primary m-t"
                            placeHolder="From Name"
                            onChange={e => this.handleInputChange(e, "fromName")}
                            value={fromName}
                        />
                        <InputField
                            classes="primary m-t"
                            placeHolder="From Email"
                            onChange={e => this.handleInputChange(e, "fromEmail")}
                            value={fromEmail}
                            error={emailError}
                        />
                        <InputField
                            classes="primary m-t"
                            placeHolder="Subject line"
                            onChange={e => this.handleInputChange(e, "subjectLine")}
                            value={subjectLine}
                        />
                        <RichTextEditor
                            classes="primary m-t"
                            editorState={editorState}
                            onEditorStateChange={this.handleRichTexboxChange}
                        />
                    </div>
                </div>
                <div className="form-buttons flex-sb-center">
                    <Button classes="purple" clickHandler={this.back.bind(this)} label="Back" />
                    <Button type="submit" classes={btnDisable ? "disabled" : "green"} label="Next" />
                </div>
            </form>
        );
    }

    renderStep3() {
        const { smsContent, isLoading, smsTemplateError } = this.state;
        const btnDisable = smsContent.length === 0 || smsTemplateError;
        let errorText = smsTemplateError
            ? `Emojis are currently unsupported for SMS Templates and could cause issues for the recipient,
        please remove the emoji for optimal deliverability.`
            : "";
        return (
            <form className="flex-100" onSubmit={this.smsTemplateFrom.bind(this)}>
                <div className="form-container fancy-scroll">
                    <div className="form-fields m-a">
                        <h3>SMS Template</h3>
                        <TextArea maxLength={150} value={smsContent} onChange={this.handleSmsTemplateChange} />

                        <div className="form-container-text__error">{errorText}</div>
                    </div>
                </div>

                <div className="form-buttons flex-sb-center">
                    <Button classes="purple" clickHandler={this.back.bind(this)} label="Back" />
                    <Button
                        type="submit"
                        loading={isLoading}
                        loadingClass="black"
                        classes={btnDisable ? "right-icon disabled" : "green right-icon"}
                        icon={partyPopper}
                        label="Create Event"
                    />
                </div>
            </form>
        );
    }

    render() {
        const { step } = this.state;
        return (
            <DialogModal classes="createEvent-dialog" closeDialog={this.handleCloseDialog}>
                <div className="createEvent-dialog__container">
                    {this.renderTopMenu()}
                    {step === 1 ? this.renderStep1() : step === 2 ? this.renderStep2() : this.renderStep3()}
                </div>
            </DialogModal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addEvent: (bodyData, media) => dispatch(addEvent(bodyData, media))
    };
};

export default connect(
    null,
    mapDispatchToProps
)(CreateEvent);
