import React, { Component } from "react";
import { connect } from "react-redux";
import { changeView } from "../../redux/actions";

import SidebarList from "./SidebarList";
import Button from "../button/Button/Button";
import { isSSOAuthenticated } from "../../utils/ssoUtils";

import {
    overviewWhite,
    overviewBlue,
    allEventsWhite,
    allEventsBlue,
    databaseWhite,
    databaseBlue,
    analyticsWhite,
    analyticsBlue,
    requestFramesWhite,
    requestFramesBlue,
    spinnerWhite
} from "../../assets/svg-icon";
import CreateEvent from "../Event/CreateEvent/CreateEvent";

class UsersMenus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createEvent: false
        };

        this.openCreateEventDialog = this.openCreateEventDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.backToAdmin = this.backToAdmin.bind(this);
    }

    openCreateEventDialog() {
        this.setState({ createEvent: true });
    }

    closeDialog() {
        this.setState({ createEvent: false });
    }

    backToAdmin() {
        const { redirectUrl } = this.props;
        this.props.changeView(false, null);
        window.location.replace(redirectUrl);
    }

    render() {
        /*TODO SidebarList render dynamic*/
        const { createEvent } = this.state;
        const { isAdmin, user, ssoUser } = this.props;
        let selectedUser = isSSOAuthenticated() ? ssoUser : user;
        const currentUser = selectedUser;
        return (
            <div>
                <div className="create-event-btn flex-center">
                    <Button
                        classes="green-gradient big sidebar__btn"
                        clickHandler={this.openCreateEventDialog}
                        label="Create New Event"
                    />
                </div>
                <nav>
                    <ul>
                        <SidebarList to="/dashboard/overview" icon={overviewWhite} hoverIcon={overviewBlue} name="Overview" />
                        <SidebarList
                            to="/dashboard/all-events"
                            icon={allEventsWhite}
                            hoverIcon={allEventsBlue}
                            name="All Events"
                        />
                        <SidebarList to="/dashboard/database" icon={databaseWhite} hoverIcon={databaseBlue} name="Database" />
                        <SidebarList to="/dashboard/analytics" icon={analyticsWhite} hoverIcon={analyticsBlue} name="Analytics" />
                        {currentUser.Company && currentUser.Company.FrameRequest === 1 && (
                            <SidebarList
                                href="https://www.digitalrange.com/creative-hub/frame-request?utm_source=range%20booth&utm_medium=navigation&utm_campaign=tab "
                                icon={requestFramesWhite}
                                hoverIcon={requestFramesBlue}
                                name="Request Frames"
                            />
                        )}
                    </ul>
                </nav>
                {createEvent ? <CreateEvent closeDialog={this.closeDialog} /> : null}
                {isAdmin && !isSSOAuthenticated() ? (
                    <div className="m-y-lg flex-center">
                        <Button
                            icon={spinnerWhite}
                            classes="red sidebar__btn"
                            clickHandler={this.backToAdmin}
                            label="Back to Admin"
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAdmin: state.auth.isAdmin,
    redirectUrl: state.auth.redirectUrl,
    user: state.auth.user,
    ssoUser: state.ssoAuth.user
});

const mapDispatchToProps = dispatch => {
    return {
        changeView: (isAdminView, url) => dispatch(changeView(isAdminView, url))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: false }
)(UsersMenus);
