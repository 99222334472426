import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Spinner } from "../../../components/Loading";
import { getAuthToken, clearSSOSession } from "../../../redux/actions";
import { isSSOAuthenticated } from "../../../utils/ssoUtils";

class SignIn extends Component {
    constructor() {
        super();
        this.state = {};
    }

    static getDerivedStateFromProps(props) {
        if (props.match.params.apiKey !== props.apiKey && props.userAuthenticated) {
            props.clearSSOSession();
        }
        return {};
    }

    componentDidMount() {
        const { match, getAuthToken } = this.props;
        const apiKey = match.params.apiKey;

        if (!isSSOAuthenticated()) {
            getAuthToken(apiKey)
                .then(res => {
                    sessionStorage.apiKey = apiKey;
                })
                .catch(err => {});
        }
    }

    render() {
        const { error, fetching } = this.props;
        if (isSSOAuthenticated()) {
            return <Redirect replce to="/dashboard/overview" />;
        }
        return (
            <div className="flex-center flex-col h-100" style={{ backgroundColor: "#F4F6FC" }}>
                {fetching && <Spinner classes="black large" />}
                <span className="m-t-xs gray-text">{!error ? "Verifying..." : "Authentication failed, unauthorized token"}</span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userAuthenticated: state.ssoAuth.userAuthenticated,
        token: state.ssoAuth.token,
        apiKey: state.ssoAuth.apiKey,
        error: state.ssoAuth.error,
        fetching: state.ssoAuth.fetching
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAuthToken: apiKey => dispatch(getAuthToken(apiKey)),
        clearSSOSession: () => dispatch(clearSSOSession())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignIn);
