import React, { Component } from "react";
import Slider from "react-slick";
import Config from "../../../config";
import { Image } from "../../components/Image";
import "./Slider.scss";

let baseUrl = Config.env().BASE_IMG_PATH;

class Sidebar extends Component {
    renderSlide() {
        const { slides, clickHandler, activeSlide } = this.props;

        return slides.map((slide, i) => {
            const path = slide.Path.includes("data:image/") ? slide.Path : `${baseUrl}${slide.Path}`;
            return (
                <div
                    key={i}
                    className={`event__slide ${activeSlide.Id === slide.Id ? "active" : ""}`}
                    onClick={() => clickHandler(slide)}>
                    <div className="event__slide-content">
                        <div className="img m-a">
                            <Image src={path} alt="frame" />
                        </div>
                    </div>
                </div>
            );
        });
    }
    render() {
        const { slides } = this.props;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: slides.length < 3 ? slides.length : 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const box = window.innerWidth <= 1400 ? 195 : 280,
            width = slides.length < 3 ? { width: `${slides.length * box}px` } : {};
        return (
            <div className="event__slider m-a" style={width}>
                <Slider {...settings}>{this.renderSlide()}</Slider>
            </div>
        );
    }
}

export default Sidebar;
