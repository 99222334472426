import React, { Component } from "react";
import { connect } from "react-redux";

import { TabTable } from "../../../components/Table";
import {
    getCompanyContactList,
    downloadContactList,
    setContactsToDownload,
    clearContactsToDownload,
    clearContactState
} from "../../../redux/actions";
import { Button } from "../../../components/button";
import { downloadWhite } from "../../../assets/svg-icon";

class Database extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false
        };
    }
    getContactList = (paramsObj, tabName) => {
        this.props.getCompanyContactList(paramsObj, tabName);
    };

    handleDownloadClick = e => {
        e.preventDefault();
        const { contactsToDownload, downloadContactList } = this.props;
        const { Ids, Type, downloadAll } = contactsToDownload;
        if (Ids.length) {
            const params = {
                Ids,
                Type
            };
            if (downloadAll) {
                delete params.Ids;
            }
            this.setState({ isLoading: true });
            downloadContactList(params).then(res => {
                let hiddenElement = document.createElement("a");
                hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(res);
                hiddenElement.target = "_blank";
                hiddenElement.download = `contact_${new Date().getTime()}.csv`;
                hiddenElement.click();
                this.setState({ isLoading: false });
            });
        }
    };

    onClearContactState = () => {
        this.props.clearContactState("companyContacts");
    };

    render() {
        const { tabState, emailList, phoneList, setContactsToDownload, clearContactsToDownload, contactsToDownload } = this.props;
        return (
            <section>
                <div className="flex-end-center m-b">
                    <Button
                        icon={downloadWhite}
                        iconWidth="1.7rem"
                        label="Download"
                        classes={`spacing medium ${contactsToDownload.Ids.length === 0 ? "disabled" : "green"}`}
                        clickHandler={this.handleDownloadClick}
                        loading={this.state.isLoading}
                    />
                </div>
                <div className="white-box">
                    <TabTable
                        tabState={tabState}
                        emailList={emailList}
                        phoneList={phoneList}
                        getContactList={this.getContactList}
                        setContactsToDownload={setContactsToDownload}
                        clearContactsToDownload={clearContactsToDownload}
                        clearContactState={this.onClearContactState}
                        tableType="contact"
                    />
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        tabState: state.contact.companyContacts.tabState,
        emailList: state.contact.companyContacts.emailList,
        phoneList: state.contact.companyContacts.phoneList,
        contactsToDownload: state.contact.contactsToDownload
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCompanyContactList: (paramsObj, key) => dispatch(getCompanyContactList(paramsObj, key)),
        downloadContactList: paramsObj => dispatch(downloadContactList(paramsObj)),
        setContactsToDownload: (ids, type, allDownload) => dispatch(setContactsToDownload(ids, type, allDownload)),
        clearContactsToDownload: () => dispatch(clearContactsToDownload()),
        clearContactState: key => dispatch(clearContactState(key))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Database);
