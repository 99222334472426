import { fetchUtil } from "../../utils/fetchUtils";
import { queryParams } from "../../utils/urlUtils";
import Config from "../../../config";
import { getToken } from "../../utils/tokenUtils";

// get contact listing by company
export const CONTACT_COMPANY_REQUEST = "CONTACT_COMPANY_REQUEST";
export const CONTACT_COMPANY_SUSSCESS = "CONTACT_COMPANY_SUSSCESS";
export const CONTACT_COMPANY_FAIL = "CONTACT_COMPANY_FAIL";

// get contact listing by event id
export const CONTACT_EVENT_REQUEST = "CONTACT_EVENT_REQUEST";
export const CONTACT_EVENT_SUSSCESS = "CONTACT_EVENT_SUSSCESS";
export const CONTACT_EVENT_FAIL = "CONTACT_EVENT_FAIL";

// set selected contacts for download
export const SET_CONTACT_TO_DOWNLOAD = "SET_CONTACT_TO_DOWNLOAD";
export const CLEAR_CONTACT_TO_DOWNLOAD = "CLEAR_CONTACT_TO_DOWNLOAD";

// clear contact state
export const CLEAR_CONTACT_STATE = "CLEAR_CONTACT_STATE";

export const getCompanyContactList = (paramObj, key) => (dispatch, getState) => {
    dispatch({ type: CONTACT_COMPANY_REQUEST, key, page: paramObj.Page });
    const token = getToken();
    fetchUtil({
        url: queryParams("contact", { Limit: 10, ...paramObj }),
        token
    })
        .then(res => {
            dispatch({ type: CONTACT_COMPANY_SUSSCESS, payload: { data: res.Contacts, total: res.TotalContacts, key } });
        })
        .catch(err => {
            dispatch({ type: CONTACT_COMPANY_FAIL, payload: err.Message });
        });
};

export const getEventContactList = (id, paramObj, key) => (dispatch, getState) => {
    dispatch({ type: CONTACT_EVENT_REQUEST, key, page: paramObj.Page });
    const token = getToken();
    fetchUtil({
        url: queryParams(`contact/${id}`, { Limit: 10, ...paramObj }),
        token
    })
        .then(res => {
            dispatch({ type: CONTACT_EVENT_SUSSCESS, payload: { data: res.Contacts, total: res.TotalContacts, key } });
        })
        .catch(err => {
            dispatch({ type: CONTACT_EVENT_FAIL, payload: err.Message });
        });
};

export const downloadContactList = params => (dispatch, getState) => {
    const token = getToken();

    const url = queryParams(`${Config.env().API_URL}contacts/download`, params);
    return fetch(url, {
        headers: {
            Authorization: token
        },
        credentials: "include"
    })
        .then(res => res.text())
        .then(res => Promise.resolve(res));
};

export const setContactsToDownload = (ids, type, downloadAll) => {
    return { type: SET_CONTACT_TO_DOWNLOAD, payload: { ids, type, downloadAll } };
};

export const clearContactsToDownload = () => {
    return { type: CLEAR_CONTACT_TO_DOWNLOAD };
};

export const clearContactState = key => {
    return { type: CLEAR_CONTACT_STATE, key };
};
