import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Spinner } from "../../../components/Loading";
import { getMe, changeView } from "../../../redux/actions";

class VerifyPage extends React.Component {
    componentDidMount() {
        const { match, location, getMe, changeView } = this.props;
        const token = match.params.token;
        let params = new URLSearchParams(location.search);
        let isAdmin = params.get("isAdmin");
        let url = params.get("url");

        localStorage.RBToken = token;
        getMe()
            .then(res => {
                if (isAdmin) {
                    changeView(Boolean(isAdmin), url);
                }
            })
            .catch(err => {});
    }

    render() {
        const { isAuthenticated, userToken, match, fetching, error } = this.props;
        if (isAuthenticated && userToken === match.params.token) {
            return <Redirect to="/dashboard/overview" />;
        }
        return (
            <div className="flex-center flex-col h-100" style={{ backgroundColor: "#F4F6FC" }}>
                {fetching && <Spinner classes="black large" />}
                <span className="m-t-xs gray-text">{!error ? "Verifying..." : "Authentication failed, unauthorized token"}</span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userToken: state.auth.user.Token,
        error: state.auth.error,
        fetching: state.auth.fetching
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMe: () => dispatch(getMe()),
        changeView: (isAdmin, url) => dispatch(changeView(isAdmin, url))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyPage);
