import React, { Component } from "react";
import { EventListing } from "../../../../components/Event";
import SearchField from "../../../../components/input/SearchField/SearchField";

import { connect } from "react-redux";
import { eventListing, clearEventListing, activeEvent } from "../../../../redux/actions";

let scrollBind = null,
    searchingText = null,
    searchQuery = false;

class AllEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: null,
            params: {
                Page: 1,
                Limit: 9
            }
        };
        this.onScroll = this.onScroll.bind(this); //bind function once
    }

    componentDidMount() {
        this.listing();
        this.scrollListerBind();
    }

    componentWillUnmount() {
        this.props.clearEventListing();
        this.scrollListerUnbind();
    }

    componentDidUpdate() {
        const { availableData } = this.props.events;
        if (!availableData && scrollBind) this.scrollListerUnbind();
        else if (availableData && !scrollBind) this.scrollListerBind();
    }

    scrollListerBind() {
        scrollBind = true;
        window.addEventListener("scroll", this.onScroll, false);
    }

    scrollListerUnbind() {
        scrollBind = false;
        window.removeEventListener("scroll", this.onScroll, false);
    }

    listing() {
        const { params } = this.state;
        this.props.eventListing(params);
    }

    onScroll(e) {
        const { fetching } = this.props.events;
        if (!fetching && window.innerHeight + window.scrollY >= document.body.offsetHeight - 50) {
            const { params } = this.state;
            params.Page++;
            this.setState({ params }, () => this.listing());
        }
    }

    searchHandler(e) {
        this.setState({ search: e.target.value });
    }

    searching(e) {
        e.preventDefault();
        let { search } = this.state,
            params = { Page: 1 };
        if (searchingText === search) return false;
        if (search) params.Name = search;
        this.props.eventListing(params);
        this.setState({ params });
        searchingText = search;
        searchQuery = !!search;
    }

    render() {
        const { search } = this.state,
            { data, fetching } = this.props.events,
            searching = search ? search : "";
        return (
            <section className="">
                <SearchField
                    classes="event-search"
                    placeHolder="Search events..."
                    value={searching}
                    onChange={this.searchHandler.bind(this)}
                    onSubmit={this.searching.bind(this)}
                />
                <EventListing />
                {!fetching && !data.length ? (
                    <div className="flex-center p-y-xlg">
                        <p className="f-22 bold secondary-text">
                            {" "}
                            {searchQuery ? "Sorry, No data match for this criteria" : "No data yet"}
                        </p>
                    </div>
                ) : null}
            </section>
        );
    }
}

const mapStateToProps = ({ events }) => ({
    events: events.listing
});

const mapDispatchToProps = dispatch => {
    return {
        eventListing: params => dispatch(eventListing(params)),
        clearEventListing: () => dispatch(clearEventListing()),
        activeEvent: (id, status) => dispatch(activeEvent(id, status))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllEvents);
