import { LOGIN_FAIL, ME_FAIL, ME_SUCCESS, SET_CODE, CHANGE_VIEW, FETCHING } from "../actions";

const initialState = {
    fetching: false,
    error: "",
    code: "",
    isAuthenticated: false,
    isAdmin: false,
    redirectUrl: null,
    user: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_FAIL:
        case ME_FAIL:
            return { ...state, isAuthenticated: false, fetching: false, error: action.payload, user: {} };
        case ME_SUCCESS:
            return {
                ...state,
                error: "",
                code: action.code,
                isAuthenticated: true,
                isAdmin: false,
                redirectUrl: null,
                user: { ...state.user, ...action.payload, Token: action.Token }
            };
        case SET_CODE:
            return {
                ...state,
                code: action.payload
            };

        case CHANGE_VIEW:
            return {
                ...state,
                isAdmin: action.payload,
                redirectUrl: action.redirectUrl
            };
        case FETCHING:
            return {
                ...state,
                error: action.payload ? "" : state.error,
                fetching: action.payload
            };
        // case SIGN_OUT:
        //     return {
        //         ...initialState
        //     };
        default:
            return state;
    }
};
