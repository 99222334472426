import {
    EVENT_LISTING_REQUEST,
    EVENT_LISTING_SUCCESS,
    EVENT_LISTING_FAIL,
    ACTIVE_EVENT_REQUEST,
    ACTIVE_EVENT_SUCCESS,
    ACTIVE_EVENT_FAIL,
    CLEAR_EVENT_LISTING,
    EVENT_MEDIA_REQUEST,
    EVENT_MEDIA_SUCCESS,
    EVENT_MEDIA_FAIL,
    CLEAR_MEDIA_STATE,
    SET_MEDIA_TO_DOWNLOAD,
    CLEAR_MEDIA_TO_DOWNLOAD,
    EVENT_DETAIL_REQUEST,
    EVENT_DETAIL_SUCCESS,
    EVENT_DETAIL_FAIL,
    UPDATE_EVENT_NAME,
    EVENT_TEMPLATE_REQUEST,
    EVENT_TEMPLATE_SUCCESS,
    EVENT_TEMPLATE_FAIL,
    GET_ACTIVE_EVENT_REQUEST,
    GET_ACTIVE_EVENT_SUCCESS,
    GET_ACTIVE_EVENT_FAIL,
    ADD_EVENT_SUCCESS,
    DELETE_FRAME_REQUEST,
    DELETE_FRAME_SUCCESS,
    DELETE_FRAME_FAIL,
    GET_ACTIVE_FRAME_REQUEST,
    GET_ACTIVE_FRAME_SUCCESS,
    GET_ACTIVE_FRAME_FAIL,
    ACTIVE_FRAME_REQUEST,
    ACTIVE_FRAME_SUCCESS,
    ACTIVE_FRAME_FAIL
} from "../actions";

import placeHolderImg from "../../assets/images/img-placeholder.jpg";

const initialState = {
    media: {
        images: {
            fetching: false,
            total: null,
            data: [],
            error: "",
            dataAvailable: true
        },
        videos: {
            fetching: false,
            total: null,
            data: [],
            error: "",
            dataAvailable: true
        },
        gifs: {
            fetching: false,
            total: null,
            data: [],
            error: "",
            dataAvailable: true
        }
    },
    mediaToDownload: {
        Ids: [],
        Type: null
    },
    listing: {
        data: [],
        availableData: true
    },
    eventDetails: {
        data: {},
        fetching: false,
        error: ""
    },
    eventTemplate: {
        data: {},
        fetching: false
    },
    activeEvent: {
        fetching: false,
        event: {
            ActiveFrameId: null
        }
    },
    frameDialogRequest: false,
    activeEventRequest: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case EVENT_LISTING_REQUEST:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    fetching: true
                }
            };
        case EVENT_LISTING_SUCCESS:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    data: (() => {
                        if (action.page && action.page > 1) return [...state.listing.data, ...action.payload.Events];
                        else return action.payload.Events;
                    })(),
                    availableData: !(action.payload.Events.length < 9),
                    fetching: false
                }
            };

        case EVENT_LISTING_FAIL:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    fetching: false
                }
            };
        case ADD_EVENT_SUCCESS:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    data: [action.payload, ...state.listing.data]
                }
            };
        case ACTIVE_EVENT_REQUEST:
            return {
                ...state,
                activeEventRequest: true
            };
        case ACTIVE_EVENT_SUCCESS:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    data: state.listing.data.map(event => {
                        event.Status = event.Id === action.payload.Id ? action.payload.Status : 0;
                        return event;
                    })
                },
                activeEventRequest: false
            };

        case ACTIVE_EVENT_FAIL:
            return {
                ...state,
                activeEventRequest: false
            };
        case CLEAR_EVENT_LISTING:
            return {
                ...state,
                listing: {
                    data: [],
                    availableData: true
                }
            };
        case EVENT_MEDIA_REQUEST:
            return {
                ...state,
                media: {
                    ...state.media,
                    [action.key]: {
                        ...state.media[action.key],
                        fetching: true
                    }
                }
            };
        case EVENT_MEDIA_SUCCESS:
            return {
                ...state,
                media: {
                    ...state.media,
                    [action.payload.key]: {
                        ...state.media[action.payload.key],
                        data: [...state.media[action.payload.key].data, ...action.payload.data],
                        fetching: false,
                        error: "",
                        total: action.payload.total,
                        ...(action.payload.data.length < 9 && { dataAvailable: false })
                    }
                }
            };
        case EVENT_MEDIA_FAIL:
            return {
                ...state,
                media: {
                    ...state.media,
                    [action.payload.key]: {
                        ...state.media[action.payload.key],
                        fetching: false,
                        error: action.payload.Message
                    }
                }
            };
        case CLEAR_MEDIA_STATE:
            return {
                ...state,
                media: initialState.media
            };
        case SET_MEDIA_TO_DOWNLOAD:
            return {
                ...state,
                mediaToDownload: {
                    ...state.mediaToDownload,
                    Ids: action.payload.ids,
                    Type: action.payload.type
                }
            };
        case CLEAR_MEDIA_TO_DOWNLOAD:
            return {
                ...state,
                mediaToDownload: {
                    ...state.mediaToDownload,
                    Ids: []
                }
            };
        case EVENT_DETAIL_REQUEST:
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    fetching: true,
                    data: {}
                }
            };
        case EVENT_DETAIL_SUCCESS:
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    data: action.payload,
                    fetching: false,
                    error: ""
                }
            };
        case EVENT_DETAIL_FAIL:
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    fetching: false,
                    error: action.payload
                }
            };
        case UPDATE_EVENT_NAME:
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    data: {
                        ...state.eventDetails.data,
                        Event: {
                            ...state.eventDetails.data.Event,
                            Name: action.Name,
                            FrameEnabled: action.FrameEnabled
                        }
                    }
                }
            };
        case EVENT_TEMPLATE_REQUEST:
            return {
                ...state,
                eventTemplate: {
                    ...state.eventTemplate,
                    fetching: true,
                    data: {}
                }
            };
        case EVENT_TEMPLATE_SUCCESS:
            return {
                ...state,
                eventTemplate: {
                    ...state.eventTemplate,
                    data: action.payload,
                    fetching: false
                }
            };
        case EVENT_TEMPLATE_FAIL:
            return {
                ...state,
                eventTemplate: {
                    ...state.eventTemplate,
                    fetching: false
                }
            };
        case GET_ACTIVE_EVENT_REQUEST:
            return {
                ...state,
                activeEvent: {
                    ...state.activeEvent,
                    fetching: true
                }
            };
        case GET_ACTIVE_EVENT_SUCCESS:
            return {
                ...state,
                activeEvent: {
                    ...state.activeEvent,
                    event: action.payload,
                    fetching: action.payload
                }
            };

        case GET_ACTIVE_FRAME_REQUEST:
            return {
                ...state,
                activeEvent: {
                    ...state.activeEvent,
                    ...(state.activeEvent.frames && {
                        frames: [
                            {
                                ...state.activeEvent.frames[0],
                                Path: placeHolderImg,
                                ThumbPath: placeHolderImg,
                                Id: (Math.random() * 100).toFixed()
                            },
                            ...state.activeEvent.frames
                        ]
                    })
                }
            };
        case GET_ACTIVE_FRAME_SUCCESS:
            return {
                ...state,
                activeEvent: {
                    ...state.activeEvent,
                    frames: action.payload.Frames,
                    total_frames: action.payload.TotalFrames,
                    fetching: false
                }
            };

        case GET_ACTIVE_EVENT_FAIL:
        case GET_ACTIVE_FRAME_FAIL:
            return {
                ...state,
                activeEvent: {
                    ...state.activeEvent,
                    fetching: false
                }
            };

        case DELETE_FRAME_REQUEST:
        case ACTIVE_FRAME_REQUEST:
            return {
                ...state,
                frameDialogRequest: true
            };
        case DELETE_FRAME_SUCCESS:
            return {
                ...state,
                activeEvent: {
                    ...state.activeEvent,
                    frames: state.activeEvent.frames.filter(frame => frame.Id !== action.payload)
                },
                frameDialogRequest: false
            };

        case ACTIVE_FRAME_SUCCESS: {
            return {
                ...state,
                frameDialogRequest: false,
                activeEvent: {
                    ...state.activeEvent,
                    event: {
                        ...state.activeEvent.event,
                        ActiveFrameId: action.payload.ActiveFrameId,
                        Frame: action.selectedFrame
                    }
                },
                listing: {
                    ...state.listing,
                    data: state.listing.data.map(event => {
                        if (event.Id === action.payload.Id) {
                            return {
                                ...event,
                                Frame: { ...action.selectedFrame }
                            };
                        }
                        return event;
                    })
                }
            };
        }

        case ACTIVE_FRAME_FAIL:
        case DELETE_FRAME_FAIL:
            return {
                ...state,
                frameDialogRequest: false
            };

        default:
            return state;
    }
};
