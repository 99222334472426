import React, { Component } from "react";
import {checkWhiteCircle} from "../../assets/svg-icon"
import  './ToasterMessage.scss';

class ToasterMessage extends Component {
    render() {
        const {text,
            icon= checkWhiteCircle
        } = this.props;
        return (
            <div className="toaster__message flex-center">
                <img className="toaster__message-icon" src={icon} alt="icon"/>
                <span className="toaster__message-text primary-font">{text}</span>
            </div>
        );
    }
}

export default ToasterMessage;
