import React, { Component } from "react";
import  { checkboxTick } from "../../../assets/svg-icon";
import  './CheckBox.scss';



class CheckBox extends Component {

    constructor(props) {
        super(props);
        this.randomId =  (((1+Math.random())*0x1000000)|0).toString(16).substring(1);
    }

    renderLabel(){
        const { label , id = this.randomId } = this.props;
        return label ? <label htmlFor={id} className="checkbox__label">{label}</label> : null
    }

    render() {

        const {
            label,
            classes,
            changeHandler,
            checked,
            id = this.randomId,
        } = this.props;

        return (
            <span className={`checkbox ${classes}`} >
                <input onChange={changeHandler} id={id} className="checkbox__input" type="checkbox" name="check" checked={checked} />
                <label htmlFor={id} className="checkbox__box" >
                    <img className="icon-check" src ={checkboxTick} alt = "" />
                </label>
                {this.renderLabel()}
            </span>
        );
    }
}

export default CheckBox;
