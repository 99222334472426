import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { ForgotPassword, Login } from "./";
import { logo } from "../../assets/svg-icon";
import { isSSOAuthenticated } from "../../utils/ssoUtils";

import "./Account.scss";

class Account extends Component {
    render() {
        const { isAuthenticated } = this.props;
        if (isAuthenticated || isSSOAuthenticated()) {
            return <Redirect to="/dashboard/overview" />;
        }

        return (
            <div className="account">
                <div className="flex-sb">
                    <div className="flex-50 h-100 account__left relative sm-hidden">
                        <h1 className="account__logo">
                            range booth
                            <img src={logo} alt="range booth" />
                        </h1>
                    </div>
                    <div className="flex-50 sm-flex-100 h-100 account__right fancy-scroll relative">
                        <div className="account__right-container flex flex-col">
                            <div className="account__have xs-flex-col">
                                <h1 className="account__logo hidden sm-visible">
                                    range booth
                                    <img src={logo} alt="range booth" />
                                </h1>
                                <span>
                                    Don't have an account?{" "}
                                    <a
                                        href="https://www.digitalrange.com/signup"
                                        target="_blank"
                                        className="uppercase primary-text bolder"
                                        rel="noopener noreferrer">
                                        Get in touch
                                    </a>
                                </span>
                            </div>
                            <div className="account__form flex-100 inline-flex-center">
                                <Switch>
                                    <Route path="/account/login" name="login" render={props => <Login {...props} />} />
                                    <Route
                                        path="/account/forgotPassword"
                                        name="forgotPassword"
                                        render={props => <ForgotPassword {...props} />}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(
    mapStateToProps,
    null
)(Account);
