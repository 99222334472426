import React, { Component } from "react";
import './Dialog.scss';


class Dialog extends Component {


    render() {
        const {children, classes=''} = this.props;

        return (
            <div id="modal-box" className={`rb-dialog ${classes}`}>
                <div className="rb-dialog__content">
                    <div id="modal-body" className="rb-dialog__body flex-center flex-col">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Dialog;
