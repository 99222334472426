import Overview from "./Overview/Overview";
import Analytics from "./Analytics/Analytics";
import Event from "./Events/Event/Event";
import AllEvents from "./Events/AllEvents/AllEvents";
import Database from "./Database/Database";

const routes = [
    {
        path: "/dashboard/overview",
        name: "overview",
        component: Overview,
        exact: true,
    },
    {
        path: "/dashboard/all-events",
        name: "all-events",
        component: AllEvents,
        exact: true,
    },
    {
        path: "/dashboard/event",
        name: "event",
        component: Event
    },
    {
        path: "/dashboard/database",
        name: "database",
        component: Database
    },
    {
        path: "/dashboard/analytics",
        name: "analytics",
        component: Analytics
    }
];

export default routes;
