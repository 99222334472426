import {
    CONTACT_COMPANY_REQUEST,
    CONTACT_COMPANY_SUSSCESS,
    CONTACT_COMPANY_FAIL,
    CONTACT_EVENT_REQUEST,
    CONTACT_EVENT_SUSSCESS,
    CONTACT_EVENT_FAIL,
    SET_CONTACT_TO_DOWNLOAD,
    CLEAR_CONTACT_TO_DOWNLOAD,
    CLEAR_CONTACT_STATE
} from "../actions";

const initialState = {
    companyContacts: {
        emailList: [],
        phoneList: [],
        error: "",
        tabState: {
            emailList: {
                initialFetch: false,
                dataAvailable: true,
                fetching: false,
                total: null
            },
            phoneList: {
                initialFetch: false,
                dataAvailable: true,
                fetching: false,
                total: null
            }
        }
    },
    eventContacts: {
        emailList: [],
        phoneList: [],
        error: "",
        tabState: {
            emailList: {
                initialFetch: false,
                dataAvailable: true,
                fetching: false,
                total: null
            },
            phoneList: {
                initialFetch: false,
                dataAvailable: true,
                fetching: false,
                total: null
            }
        }
    },
    contactsToDownload: {
        Ids: [],
        Type: null,
        downloadAll: false
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CONTACT_COMPANY_REQUEST:
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    [action.key]: action.page === 1 ? [] : state.companyContacts[action.key],
                    tabState: {
                        ...state.companyContacts.tabState,
                        [action.key]: {
                            ...state.companyContacts.tabState[action.key],
                            fetching: true,
                            dataAvailable: true
                        }
                    }
                }
            };
        case CONTACT_COMPANY_SUSSCESS:
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    [action.payload.key]: [...state.companyContacts[action.payload.key], ...action.payload.data],
                    error: "",
                    tabState: {
                        ...state.companyContacts.tabState,
                        [action.payload.key]: {
                            ...state.companyContacts.tabState[action.payload.key],
                            fetching: false,
                            initialFetch: true,
                            total: action.payload.total,
                            ...(action.payload.data.length < 10 && { dataAvailable: false })
                        }
                    }
                }
            };
        case CONTACT_COMPANY_FAIL:
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    error: action.payload
                }
            };
        case CONTACT_EVENT_REQUEST:
            return {
                ...state,
                eventContacts: {
                    ...state.eventContacts,
                    [action.key]: action.page === 1 ? [] : state.eventContacts[action.key],
                    tabState: {
                        ...state.eventContacts.tabState,
                        [action.key]: {
                            ...state.eventContacts.tabState[action.key],
                            fetching: true,
                            dataAvailable: true
                        }
                    }
                }
            };
        case CONTACT_EVENT_SUSSCESS:
            return {
                ...state,
                eventContacts: {
                    ...state.eventContacts,
                    [action.payload.key]: [...state.eventContacts[action.payload.key], ...action.payload.data],
                    error: "",
                    tabState: {
                        ...state.eventContacts.tabState,
                        [action.payload.key]: {
                            ...state.eventContacts.tabState[action.payload.key],
                            fetching: false,
                            initialFetch: true,
                            total: action.payload.total,
                            ...(action.payload.data.length < 10 && { dataAvailable: false })
                        }
                    }
                }
            };
        case CONTACT_EVENT_FAIL:
            return {
                ...state,
                eventContacts: {
                    ...state.eventContacts,
                    error: action.payload
                }
            };
        case SET_CONTACT_TO_DOWNLOAD:
            return {
                ...state,
                contactsToDownload: {
                    ...state.contactsToDownload,
                    Ids: action.payload.ids,
                    Type: action.payload.type,
                    downloadAll: action.payload.downloadAll
                }
            };
        case CLEAR_CONTACT_TO_DOWNLOAD:
            return {
                ...state,
                contactsToDownload: initialState.contactsToDownload
            };
        case CLEAR_CONTACT_STATE:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    tabState: initialState[action.key].tabState
                }
            };
        default:
            return state;
    }
};
