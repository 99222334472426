import React, { Component } from "react";
import { EventListing, CurrentEvent } from "../../../components/Event";
import { Button } from "../../../components/button";
import { connect } from "react-redux";
import { eventListing, clearEventListing, getActiveEvent } from "../../../redux/actions";

import "./Overview.scss";

const OVERVIEW_STATE = {
    PAGE: 1,
    LIMIT: 6
};

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {
                Page: OVERVIEW_STATE.PAGE,
                Limit: OVERVIEW_STATE.LIMIT
            }
        };
    }

    componentDidMount() {
        this.listing();
        this.props.getActiveEvent();
    }

    componentWillUnmount() {
        this.props.clearEventListing();
    }

    listing() {
        const { params } = this.state;
        this.props.eventListing(params);
    }

    renderViewAllEvents() {
        const { data } = this.props.events;
        return !(data.length < OVERVIEW_STATE.LIMIT) ? (
            <div className="flex-center m-y">
                <Button to="/dashboard/all-events" classes="purple big" label="View All Events " />
            </div>
        ) : null;
    }

    render() {
        const { data, fetching } = this.props.events;
        return (
            <div>
                <CurrentEvent />
                <EventListing />
                {!fetching && !data.length ? (
                    <div className="flex-center p-y-xlg">
                        <p className="f-22 bold secondary-text">No Event data yet</p>
                    </div>
                ) : null}

                {this.renderViewAllEvents()}
            </div>
        );
    }
}

const mapStateToProps = ({ events }) => ({
    events: events.listing,
    activeEvent: events.activeEvent
});

const mapDispatchToProps = dispatch => {
    return {
        eventListing: params => dispatch(eventListing(params)),
        clearEventListing: () => dispatch(clearEventListing()),
        getActiveEvent: (id, status) => dispatch(getActiveEvent(id, status))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Overview);
