import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    bold,
    bullets,
    centerAlign,
    leftAlign,
    rightAlign,
    italics,
    underline,
    textColor,
    numberList,
    link
} from "../../../assets/svg-icon";
import "./RichTextEditor.scss";

const config = {
    options: ["inline", "colorPicker", "textAlign", "list", "link"],
    inline: {
        className: "options-box",
        options: ["bold", "italic", "underline"],
        bold: {
            icon: bold,
            className: "options-box-icon bold"
        },
        italic: {
            icon: italics,
            className: "options-box-icon italic"
        },
        underline: {
            icon: underline,
            className: "options-box-icon underline"
        }
    },
    textAlign: {
        className: "options-box",
        options: ["left", "center", "right"],
        left: {
            icon: leftAlign,
            className: "options-box-icon left"
        },
        center: {
            icon: centerAlign,
            className: "options-box-icon center"
        },
        right: {
            icon: rightAlign,
            className: "options-box-icon right"
        }
    },
    list: {
        className: "options-box",
        options: ["unordered", "ordered"],
        unordered: {
            icon: bullets,
            className: "options-box-icon bullets"
        },
        ordered: {
            icon: numberList,
            className: "options-box-icon number-list"
        }
    },
    colorPicker: {
        className: "options-box className options-box-icon color-picker",
        icon: textColor,
        colors: [
            "rgb(97,189,109)",
            "rgb(26,188,156)",
            "rgb(84,172,210)",
            "rgb(44,130,201)",
            "rgb(147,101,184)",
            "rgb(71,85,119)",
            "rgb(204,204,204)",
            "rgb(65,168,95)",
            "rgb(0,168,133)",
            "rgb(61,142,185)",
            "rgb(41,105,176)",
            "rgb(85,57,130)",
            "rgb(40,50,78)",
            "rgb(0,0,0)",
            "rgb(247,218,100)",
            "rgb(251,160,38)",
            "rgb(235,107,86)",
            "rgb(226,80,65)",
            "rgb(163,143,132)",
            "rgb(239,239,239)",
            "rgb(255,255,255)",
            "rgb(250,197,28)",
            "rgb(243,121,52)",
            "rgb(209,72,65)",
            "rgb(184,49,47)",
            "rgb(124,112,107)",
            "rgb(209,213,216)"
        ]
    },
    link: {
        className: "options-box",
        showOpenOptionOnHover: true,
        defaultTargetOption: "_self",
        options: ["link"],
        link: {
            icon: link,
            className: "options-box-icon link"
        }
    }
};
class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { editorState, onEditorStateChange } = this.props;
        return (
            <div className="rich-text-editor">
                <div className="rich-text-editor__container">
                    <Editor
                        editorState={editorState}
                        toolbarClassName="rich-text-editor__toolbar"
                        wrapperClassName="rich-text-editor__wrapper"
                        editorClassName="rich-text-editor__text-area"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={config}
                    />
                </div>
                <p className="short-code">
                    <span className="a border-none">https://drbooth.co/m/a94d2e0</span>
                </p>
            </div>
        );
    }
}

export default RichTextEditor;
