import { toast } from "react-toastify";

export const logger = msg => {
    if (process.env.NODE_ENV === "development") {
        console.log('logger...', msg);
    }
};

export const errorToaster = msg => {
    toast.error(msg.Message || 'Some thing went wrong try again later!');
};
