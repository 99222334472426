import React, { Component } from "react";
import "./Table.scss";
class Table extends Component {
    render() {
        const { children, classes, height, onScroll, forwardRef = null } = this.props,
            maxHeight = height ? { maxHeight: `${height}px` } : {};
        return (
            <section className={`table-section fancy-scroll ${classes}`} style={maxHeight} onScroll={onScroll} ref={forwardRef}>
                <table className="flex-100">{children}</table>
            </section>
        );
    }
}

export default Table;
