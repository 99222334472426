import { ANALYTICS_DATA_REQUEST, ANALYTICS_DATA_SUCCESS, ANALYTICS_DATA_FAIL } from "../actions";

const initialState = {
    fetching: false,
    error: "",
    data: {
        DailyMediaCapturedData: {},
        TotalEmail: null,
        TotalSMS: null,
        TotalMedia: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ANALYTICS_DATA_REQUEST:
            return {
                ...state,
                fetching: true,
                data: initialState.data
            };
        case ANALYTICS_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: { ...state.data, ...action.payload }
            };
        case ANALYTICS_DATA_FAIL:
            return {
                ...state,
                fetching: false,
                error: action.payload
            };
        default:
            return state;
    }
};
