import { fetchUtil } from "../../utils/fetchUtils";
import { logger, errorToaster } from "../../utils/loggerUtils";
import { queryParams } from "../../utils/urlUtils";

// auth for single sign on
export const AUTH_TOKEN_REQUEST = "AUTH_TOKEN_REQUEST";
export const AUTH_TOKEN_SUCCESS = "AUTH_TOKEN_SUCCESS";
export const AUTH_TOKEN_FAIL = "AUTH_TOKEN_FAIL";
// me call for single sign on
export const SSO_ME_SUCCESS = "SSO_ME_SUCCESS";
export const SSO_ME_FAIL = "SSO_ME_FAIL";
// clear previous SSO session
export const CLEAR_SSO_SESSION = "CLEAR_SSO_SESSION";

export const getAuthToken = apiKey => (dispatch, getState) => {
    dispatch({ type: AUTH_TOKEN_REQUEST });
    return fetchUtil({
        url: queryParams("auth-token", { ApiKey: apiKey }),
        method: "POST"
    })
        .then(res => {
            dispatch({ type: AUTH_TOKEN_SUCCESS, payload: res.Token, apiKey });
            dispatch(ssoGetMe());
            return Promise.resolve(res);
        })
        .catch(err => {
            dispatch({ type: AUTH_TOKEN_FAIL, payload: err.Message });
            return Promise.reject(err);
        });
};

export const ssoGetMe = () => (dispatch, getState) => {
    const token = getState().ssoAuth.token;
    if (token) {
        fetchUtil({
            url: "me",
            token
        })
            .then(res => {
                dispatch({
                    type: SSO_ME_SUCCESS,
                    payload: res.User
                });
            })
            .catch(err => {
                errorToaster(err);
                logger(err);
                dispatch({ type: SSO_ME_FAIL, payload: err });
            });
    } else {
        dispatch({ type: SSO_ME_FAIL, payload: false });
    }
};

export const clearSSOSession = () => {
    return { type: CLEAR_SSO_SESSION };
};
