import React, { Component } from "react";
import "./Image.scss";

class Image extends Component {
    constructor() {
        super();
        this.state = {
            imageLoading: true
        };
    }

    handleImageLoaded = () => {
        this.setState({ imageLoading: false });
    };

    render() {
        const { src, alt = "frame", classes = "" } = this.props;
        const { imageLoading } = this.state;
        return (
            <React.Fragment>
                <div className={`rb-image  ${classes} ${imageLoading && "loading"}`}>
                    <img src={src} alt={alt} onLoad={this.handleImageLoaded} />
                </div>
            </React.Fragment>
        );
    }
}

export default Image;
