import React, { Component } from "react";
import  './Select.scss';


class Select extends Component {


    renderLabel(){
        const { label } = this.props;
        return label ? <label className="gray-text m-b-xs bold">{label}</label> : null
    }

    render() {

        const {
            classes = '',
            placeHolder = 'Select option',
            children,
            onChange,
            value = '',
            ...rest
        } = this.props;

        return (
            <fieldset className={`field-set ${classes}`}>
                {this.renderLabel()}
                <select onChange={onChange} value={value} className="fancy-scroll" {...rest}>
                    <option value="">{placeHolder}</option>
                    {children}
                </select>
            </fieldset>

        );
    }
}

export default Select;
