import React, { Component } from "react";
import "./InputField.scss";
import { uploadBlack } from "../../../assets/svg-icon";

class InputField extends Component {
    renderLabel() {
        const { label } = this.props;
        return label ? <label className="gray-text bold">{label}</label> : null;
    }

    render() {
        const {
            type = "text",
            label,
            classes = "",
            onChange,
            value = "",
            placeHolder = label || "",
            error = false,
            ...rest
        } = this.props;

        return (
            <fieldset className={`field-set ${classes} ${error ? "error" : ""}`}>
                {this.renderLabel()}
                {type === "file" ? (
                    <div className="input-file">
                        <span className="place-holder">{placeHolder}</span>
                        <img className="upload-icon" src={uploadBlack} alt="" />
                        <input type={type} placeholder={placeHolder} {...rest} value={value} onChange={onChange} />
                    </div>
                ) : (
                    <input type={type} placeholder={placeHolder} {...rest} value={value} onChange={onChange} />
                )}
            </fieldset>
        );
    }
}

export default InputField;
