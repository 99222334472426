import React, { Component } from "react";

import { IconButton } from "../../button/index";
import imgPlaceholder from "../../../assets/images/img-placeholder.jpg";
import { checkWhite, deleteWhite, eventDetailsWhite, uploadWhite, warningAlert } from "../../../assets/svg-icon";
import Config from "../../../../config";
import { connect } from "react-redux";
import { getActiveEvent, activeFrame, deleteFrame, uploadMedia, getFrames } from "../../../redux/actions";
import { Dialog, DialogContent } from "../../Dialog";
import { toast } from "react-toastify";
import "./CurrentEvent.scss";
import Spinner from "../../Loading/Spinner/Spinner";
import Slider from "../../Slider/Slider";

import { Image } from "../../../components/Image";
import Button from "../../button/Button/Button";
import ToasterMessage from "../../ToasterMessage/ToasterMessage";

let baseUrl = Config.env().BASE_IMG_PATH,
    activeFrameDialog = false;

const activeFrameDialogContent = {
    icon: warningAlert,
    title: "Activate Frame",
    text: "Are you sure you would like to active this frame for the event?",
    actionLabel: "Yes!",
    actionClass: "green"
};

const deActiveFrameDialogContent = {
    title: "Delete Frame",
    text: "Are you sure you would like to delete this frame?",
    actionLabel: "Delete",
    actionClass: "red"
};

class CurrentEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            frameView: null,
            uploadRequest: false
        };
        this.activateHandler = this.activateHandler.bind(this);
        this.deActivateHandler = this.deActivateHandler.bind(this);
        this.actionDialog = this.actionDialog.bind(this);
        this.cancelDialog = this.cancelDialog.bind(this);
        this.slideClick = this.slideClick.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    activateHandler() {
        activeFrameDialog = true;
        this.setState({ dialogOpen: true });
    }

    deActivateHandler() {
        activeFrameDialog = false;
        this.setState({ dialogOpen: true });
    }

    actionDialog() {
        const frameRequest = activeFrameDialog ? this.props.activeFrame : this.props.deleteFrame;

        const { event } = this.props.activeEvent,
            { frameView } = this.state,
            activeSlide = frameView || event.Frame;

        frameRequest(event.Id, activeSlide.Id).then(res => {
            toast(<ToasterMessage text="Changes have been saved!" />, { type: "success" });
            this.cancelDialog();
            !activeFrameDialog && this.setState({ frameView: null });
        });
    }

    cancelDialog() {
        this.setState({ dialogOpen: false });
    }

    slideClick(slide) {
        this.setState({ frameView: slide });
    }

    handleFileUpload(e) {
        const { event } = this.props.activeEvent;
        const frame = e.target.files[0];
        this.setState({ uploadRequest: true });
        this.props
            .uploadMedia(frame, event.Id, 1)
            .then(res => {
                toast(<ToasterMessage text="Your frame was uploaded!" />, { type: "success" });
                this.props.getFrames(event.Id);
            })
            .finally(_ => {
                this.setState({ uploadRequest: false });
            });
    }

    renderDialog() {
        const { dialogOpen } = this.state;
        const { frameDialogRequest } = this.props;
        const dialogContent = activeFrameDialog ? activeFrameDialogContent : deActiveFrameDialogContent;
        return dialogOpen ? (
            <Dialog>
                <DialogContent
                    icon={dialogContent.icon}
                    title={dialogContent.title}
                    text={dialogContent.text}
                    actionLabel={dialogContent.actionLabel}
                    actionClass={dialogContent.actionClass}
                    loading={frameDialogRequest}
                    action={this.actionDialog.bind(this)}
                    cancel={this.cancelDialog.bind(this)}
                />
            </Dialog>
        ) : null;
    }

    renderFrames() {
        const { frames, event } = this.props.activeEvent,
            { frameView } = this.state,
            activeSlide = frameView || event.Frame;
        return frames ? (
            <div className="current-event-slider m-t-xs">
                <Slider slides={frames} activeSlide={activeSlide} clickHandler={this.slideClick} />
            </div>
        ) : null;
    }

    renderButton() {
        const { event } = this.props.activeEvent;
        const { uploadRequest } = this.state;
        return (
            <div className="events-buttons flex-center m-t-xs xs-flex-col">
                <Button
                    iconWidth="2.8rem"
                    loading={uploadRequest}
                    type="file"
                    classes="green medium m-r"
                    icon={uploadWhite}
                    label="Upload Frame"
                    clickHandler={this.handleFileUpload}
                />
                <Button
                    to={`/dashboard/event/detail/${event.Id}`}
                    classes="purple medium"
                    icon={eventDetailsWhite}
                    label="Event Details"
                />
            </div>
        );
    }

    renderCurrentEvent() {
        const { event } = this.props.activeEvent,
            currentFrame = this.state.frameView || event.Frame,
            frame = currentFrame && currentFrame.Path ? `${baseUrl}${currentFrame.Path}` : imgPlaceholder,
            disabled = event && currentFrame && event.ActiveFrameId === currentFrame.Id;
        return event ? (
            <div className="flex-center flex-col p-b-sm p-t-xs">
                <div className="current-event">
                    <h3 className="current-event__label uppercase center secondary-font m-t-xs">Current Event</h3>
                    <h4 className="current-event__title center">{event.Name}</h4>
                    <div className="current-event__box">
                        <div className="current-event__img  m-a">
                            <Image src={frame} alt="frame" />
                        </div>
                        <div className="flex-center current-event__btn m-t-xs">
                            <IconButton
                                disabled={disabled}
                                clickHandler={this.activateHandler}
                                icon={checkWhite}
                                classes={`m-r-xs ${!disabled ? "green" : "disabled"}`}
                            />
                            <IconButton
                                clickHandler={this.deActivateHandler}
                                icon={deleteWhite}
                                classes={disabled ? "disabled" : "red"}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
                {this.renderFrames()}
                {this.renderButton()}
                {this.renderDialog()}
            </div>
        ) : (
            <div className="flex-center p-y-xlg">
                <p className="f-22 secondary-text">Current no event active</p>
            </div>
        );
    }

    renderData() {
        const { fetching } = this.props.activeEvent;
        return fetching ? (
            <div className="flex-center p-y-xlg">
                <Spinner classes="large green" />
            </div>
        ) : (
            this.renderCurrentEvent()
        );
    }

    render() {
        return <section className="white-box m-b-md">{this.renderData()}</section>;
    }
}

const mapStateToProps = ({ events }) => ({
    activeEvent: events.activeEvent,
    frameDialogRequest: events.frameDialogRequest
});

const mapDispatchToProps = dispatch => {
    return {
        getActiveEvent: (id, status) => dispatch(getActiveEvent(id, status)),
        activeFrame: (id, activeFrameId) => dispatch(activeFrame(id, activeFrameId)),
        deleteFrame: (id, frameId) => dispatch(deleteFrame(id, frameId)),
        uploadMedia: (frame, eventId, isframe) => dispatch(uploadMedia(frame, eventId, isframe)),
        getFrames: eventId => dispatch(getFrames(eventId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrentEvent);
