import React, { Component } from "react";

import { ContactTypes } from "../../config/constants";
import { Table, HeadTr } from "./";
import { TabButton } from "../../components/button";
import { CheckBox } from "../../components/input";
import { phoneBlack, phoneGreen, emailGreen, emailBlack, sortWhite } from "../../assets/svg-icon";
import { Spinner } from "../Loading";
import IconButton from "../button/IconButton/IconButton";

const TABNAME = {
    EMAIL: "emailList",
    PHONE: "phoneList"
};

const MEMBERTYPE = {
    "0": "No",
    "1": "Yes"
};

class TabTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: TABNAME.PHONE,
            emailList: {
                data: [],
                checked: false,
                pageNo: 1
            },
            phoneList: {
                data: [],
                checked: false,
                pageNo: 1
            },
            sortingState: {
                Name: "ASC",
                Email: "",
                Phone: "",
                Member: ""
            },
            currentSortBy: "Name"
        };
        this.scrollDiv = React.createRef();
    }
    componentDidMount() {
        const { getContactList } = this.props;
        const { emailList, phoneList } = this.state;
        getContactList({ FilterType: ContactTypes.PHONE, Page: phoneList.pageNo }, TABNAME.PHONE);
        getContactList({ FilterType: ContactTypes.EMAIL, Page: emailList.pageNo }, TABNAME.EMAIL);
        this.props.clearContactsToDownload();
    }

    componentDidUpdate(prevProps) {
        const { currentTab } = this.state;
        if (this.props[currentTab] !== prevProps[currentTab]) {
            let Ids = [];
            if (this.state[currentTab].data.length) {
                this.state[currentTab].data.forEach(item => {
                    if (item.checked) {
                        Ids.push(item.Id);
                    }
                });
            }
            const data = this.props[currentTab].map(value => {
                if (this.state[currentTab].checked) {
                    return { ...value, checked: true };
                } else {
                    if (Ids.includes(value.Id)) {
                        return { ...value, checked: true };
                    }
                    return { ...value, checked: false };
                }
            });
            this.setState({ [currentTab]: { ...this.state[currentTab], data } });
        }
    }

    componentWillUnmount() {
        this.props.clearContactState();
    }

    handleTabClick = tabName => {
        const { getContactList, tabState } = this.props;
        const currentTabState = tabState[tabName];
        const filterType = tabName === TABNAME.EMAIL ? "EMAIL" : "PHONE";
        this.scrollDiv.current.scrollTop = 0;
        if (!currentTabState.initialFetch && !currentTabState.fetching) {
            getContactList({ FilterType: ContactTypes[filterType], Page: 1 }, tabName);
        } else if (currentTabState.initialFetch) {
            if (this.props[tabName].length !== 0) {
                const data = this.props[tabName].map(value => {
                    return { ...value, checked: false };
                });
                this.setState({ [tabName]: { ...this.state[tabName], data, checked: false } });
            }
        }

        this.setState({ currentTab: tabName });
        this.props.clearContactsToDownload();
    };

    handleScroll = e => {
        const { currentTab, currentSortBy, sortingState } = this.state;
        const { tabState, getContactList } = this.props;
        const filterType = currentTab === TABNAME.EMAIL ? "EMAIL" : "PHONE";
        const page = this.state[currentTab].pageNo + 1;
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
        if (bottom && !tabState[currentTab].fetching && tabState[currentTab].dataAvailable) {
            getContactList(
                {
                    FilterType: ContactTypes[filterType],
                    Page: page,
                    Column: currentSortBy,
                    Direction: sortingState[currentSortBy]
                },
                currentTab
            );
            this.setState({ [currentTab]: { ...this.state[currentTab], pageNo: page } });
        }
    };

    handleCheckClick = (e, index) => {
        const { currentTab } = this.state;
        let data = this.state[currentTab].data;
        let headerChecked = this.state[currentTab].checked;
        const type = currentTab === TABNAME.EMAIL ? "EMAIL" : "PHONE";
        const userIds = [];
        if (index === "header") {
            data = data.map(values => {
                return { ...values, checked: e.target.checked };
            });
            this.setState({ [currentTab]: { ...this.state[currentTab], data, checked: e.target.checked } });
            headerChecked = e.target.checked;
        } else {
            data[index].checked = e.target.checked;
            this.setState({ [currentTab]: { ...this.state[currentTab], data } });
        }
        data.forEach(item => {
            if (item.checked) {
                userIds.push(item.Id);
            }
        });
        this.props.setContactsToDownload(userIds, ContactTypes[type], headerChecked);
    };

    handleSortButton = tab => {
        const { sortingState, currentTab } = this.state;
        let currentTabOrder = sortingState[tab];
        const type = currentTab === TABNAME.EMAIL ? "EMAIL" : "PHONE";
        if (currentTabOrder === "") {
            currentTabOrder = "ASC";
        } else {
            currentTabOrder = currentTabOrder === "ASC" ? "DESC" : currentTabOrder === "DESC" ? "ASC" : "";
        }
        this.props.getContactList(
            { FilterType: ContactTypes[type], Page: 1, Column: tab, Direction: currentTabOrder },
            currentTab
        );
        this.setState({
            sortingState: { ...this.state.sortingState, [tab]: currentTabOrder },
            currentSortBy: tab,
            [currentTab]: { ...this.state[currentTab], pageNo: 1 }
        });
    };

    renderTabs = () => {
        const { currentTab } = this.state;
        const { tabState } = this.props;
        return (
            <div className="flex-center flex-align-stretch">
                <TabButton
                    clickHandler={() => this.handleTabClick(TABNAME.PHONE)}
                    label={tabState.phoneList.total}
                    iconWidth="2.5rem"
                    icon={phoneBlack}
                    activeIcon={phoneGreen}
                    classes={currentTab === TABNAME.PHONE ? "active" : ""}
                />
                <TabButton
                    clickHandler={() => this.handleTabClick(TABNAME.EMAIL)}
                    label={tabState.emailList.total}
                    icon={emailBlack}
                    activeIcon={emailGreen}
                    classes={currentTab === TABNAME.EMAIL ? "active" : ""}
                />
            </div>
        );
    };

    renderTableHeader = () => {
        const { currentTab } = this.state;
        const { tableType } = this.props;
        const checked = this.state[currentTab].checked;
        return (
            <HeadTr>
                <th className="checkbox-col">
                    <CheckBox classes="white" changeHandler={e => this.handleCheckClick(e, "header")} checked={checked} />
                </th>
                <th>
                    Name <IconButton icon={sortWhite} clickHandler={() => this.handleSortButton("Name")} />
                </th>
                {currentTab === TABNAME.EMAIL ? (
                    <th>
                        Email
                        <IconButton icon={sortWhite} clickHandler={() => this.handleSortButton("Email")} />
                    </th>
                ) : (
                    <th>
                        Phone
                        <IconButton icon={sortWhite} clickHandler={() => this.handleSortButton("Phone")} />
                    </th>
                )}
                {(tableType === "event" && this.props.collectingMembers) || tableType === "contact" ? (
                    <th>
                        Member? <IconButton icon={sortWhite} clickHandler={() => this.handleSortButton("Member")} />
                    </th>
                ) : null}
            </HeadTr>
        );
    };

    renderTableData = () => {
        const { currentTab } = this.state;
        const { tableType } = this.props;
        const data = this.state[currentTab].data;
        return (
            <tbody>
                {data.map((items, index) => {
                    return (
                        <tr key={index}>
                            <th className="checkbox-col">
                                <CheckBox checked={items.checked} changeHandler={e => this.handleCheckClick(e, index)} />
                            </th>
                            <td>{items.Name}</td>
                            <td>{currentTab === TABNAME.EMAIL ? items.Email : items.PhoneNumber}</td>
                            {(tableType === "event" && this.props.collectingMembers) || tableType === "contact" ? (
                                <td>{tableType === "contact" && items.Member === null ? "-" : MEMBERTYPE[items.Member]}</td>
                            ) : null}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    render() {
        const { tabState } = this.props;
        const { currentTab } = this.state;
        const data = this.state[currentTab].data;
        const fetching = tabState[currentTab].fetching;
        return (
            <React.Fragment>
                {this.renderTabs()}
                <Table classes="infinite-scroll" height={550} onScroll={this.handleScroll} forwardRef={this.scrollDiv}>
                    {this.renderTableHeader()}
                    {data.length !== 0 && this.renderTableData()}
                </Table>
                {!fetching && data.length === 0 && <div className="flex-center p-y-xlg">no data avilable</div>}
                {fetching && data.length > 0 ? (
                    <div className="flex-center p-y">
                        <Spinner classes="green small" />
                    </div>
                ) : null}
                {fetching && data.length === 0 ? (
                    <div className="flex-center p-y-xlg">
                        <Spinner classes="green" />
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}

export default TabTable;
