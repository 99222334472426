import React, { Component } from "react";
import  './SearchField.scss';

import {search} from "../../../assets/svg-icon"


class SearchField extends Component {


    renderLabel(){
        const { label , id='' } = this.props;
        return label ? <label htmlFor={id} className="gray-text m-b-xs bold">{label}</label> : null
    }

    render() {

        const {
            value,
            classes = '',
            onSubmit,
            onChange,
            placeHolder = 'Search ...',
        } = this.props;

        return (
            <form className="search-field" onSubmit={onSubmit}>
                <fieldset className={classes}>
                    <span className="search-icon"><img src={search} alt="icon" /> </span>
                    <input onChange={onChange} onBlur={onSubmit}  type='text' placeholder={placeHolder} value={value} />
                </fieldset>
                <button type="submit" className="hidden"></button>
            </form>

        );
    }
}

export default SearchField;
