import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, NavLink } from "react-router-dom";

import "./Event.scss";
import routes from "./router";
import { downloadContactList, downloadMedia, getEventDetails } from "../../../../redux/actions";
import { Button, IconButton } from "../../../../components/button";
import { leftArrow, downloadWhite, warningAlert } from "../../../../assets/svg-icon";
import { Dialog, DialogContent } from "../../../../components/Dialog";

const navLinks = [
    { title: "Details", path: "/dashboard/event/detail/" },
    { title: "Media", path: "/dashboard/event/media/" },
    { title: "Data", path: "/dashboard/event/data/" },
    { title: "Template", path: "/dashboard/event/template/" }
];

class Event extends Component {
    constructor() {
        super();
        this.state = {
            dialogOpen: false,
            dialogText: "",
            dataBtnLoading: false,
            mediaBtnLoading: false
        };
    }

    componentDidMount() {
        const {
            location: { pathname }
        } = this.props;
        const id = pathname.split("/").pop();
        this.props.getEventDetails(id);
    }

    handleDownloadClick = e => {
        e.preventDefault();
        const {
            location: { pathname }
        } = this.props;
        const id = pathname.split("/").pop();
        if (pathname.includes("data")) {
            const { contactsToDownload, downloadContactList } = this.props;
            const { Ids, Type, downloadAll } = contactsToDownload;
            if (Ids.length) {
                const params = {
                    Ids,
                    Type,
                    EventId: id
                };
                if (downloadAll) {
                    delete params.Ids;
                }
                this.setState({ dataBtnLoading: true });
                downloadContactList(params).then(res => {
                    let hiddenElement = document.createElement("a");
                    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(res);
                    hiddenElement.target = "_blank";
                    hiddenElement.download = `contact_${new Date().getTime()}.csv`;
                    hiddenElement.click();
                    this.setState({ dataBtnLoading: false });
                });
            }
        } else {
            const { mediaToDownload, downloadMedia } = this.props;
            const { Ids, Type } = mediaToDownload;
            this.setState({ mediaBtnLoading: true });
            if (Ids.length) {
                downloadMedia(id, { Ids, Type }).then(res => {
                    this.setState({ dialogOpen: true, dialogText: res.Message, mediaBtnLoading: false });
                });
            } else {
                downloadMedia(id, { Type }).then(res => {
                    this.setState({ dialogOpen: true, dialogText: res.Message, mediaBtnLoading: false });
                });
            }
        }
    };

    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };

    goBack() {
        console.log(" this.props.history", this.props.history);
        this.props.history.goBack();
    }

    renderNav() {
        const {
            location: { pathname }
        } = this.props;
        const id = pathname.split("/").pop();
        return navLinks.map((nav, index) => {
            return (
                <li key={index}>
                    <NavLink className="uppercase" activeClassName="active" to={nav.path + id}>
                        {nav.title}
                    </NavLink>
                </li>
            );
        });
    }

    childViews() {
        return (
            <Switch>
                {routes.map((route, index) => {
                    return route.component ? (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => <route.component {...props} />}
                        />
                    ) : null;
                })}
            </Switch>
        );
    }

    renderDialog() {
        const { dialogOpen, dialogText } = this.state;
        return dialogOpen ? (
            <Dialog>
                <DialogContent
                    icon={warningAlert}
                    title="Successful"
                    text={dialogText}
                    actionLabel="Ok"
                    actionClass="green"
                    action={this.closeDialog}
                    hideCancel
                />
            </Dialog>
        ) : null;
    }

    render() {
        const {
            location: { pathname },
            eventData
        } = this.props;
        const eventName = eventData.hasOwnProperty("Event") && eventData.Event.Name;
        const showDownloadBtn = pathname.includes("media") || pathname.includes("data");
        const mediaPageBtn = pathname.includes("media");
        return (
            <div>
                <section className="white-box event-details">
                    <div className="">
                        <div className="flex-sb-center event-details__header">
                            <span className="event-details__header-back flex-start-center">
                                <IconButton classes="transparent" icon={leftArrow} clickHandler={() => this.goBack()} />
                            </span>
                            <h3 className="event-details__header-name capitalize secondary-font flex-grow flex-center">
                                {eventName}
                            </h3>
                            <div className="event-details__header-button inline-flex-end">
                                {showDownloadBtn ? (
                                    !mediaPageBtn ? (
                                        <Button
                                            classes={this.props["contactsToDownload"].Ids.length === 0 ? "disabled" : "green"}
                                            iconWidth="2rem"
                                            label="Download"
                                            icon={downloadWhite}
                                            loading={this.state.dataBtnLoading}
                                            clickHandler={this.handleDownloadClick}
                                        />
                                    ) : (
                                        <Button
                                            classes="green"
                                            iconWidth="2rem"
                                            label="Download"
                                            icon={downloadWhite}
                                            loading={this.state.mediaBtnLoading}
                                            clickHandler={this.handleDownloadClick}
                                        />
                                    )
                                ) : (
                                    <span>&nbsp;</span>
                                )}
                            </div>
                        </div>
                        <nav className="event-details__nav m-x">
                            <ul className="flex-sb-center p-x-xlg">{this.renderNav()}</ul>
                        </nav>
                    </div>
                    {this.childViews()}
                </section>
                {this.renderDialog()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        contactsToDownload: state.contact.contactsToDownload,
        mediaToDownload: state.events.mediaToDownload,
        eventData: state.events.eventDetails.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        downloadContactList: paramsObj => dispatch(downloadContactList(paramsObj)),
        downloadMedia: (id, paramsObj) => dispatch(downloadMedia(id, paramsObj)),
        getEventDetails: id => dispatch(getEventDetails(id))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Event);
