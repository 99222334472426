import React, { Component } from "react";
import Event from "../Event/Event";
import "./EventListing.scss";
import { connect } from "react-redux";
import { activeEvent } from "../../../redux/actions";
import { toast } from "react-toastify";
import { Dialog, DialogContent } from "../../Dialog";
import Loading from "../../Loading/Loading/Loading";
import { warningAlert } from "../../../assets/svg-icon";
import ToasterMessage from "../../ToasterMessage/ToasterMessage";

let activeEventRequest = null;

const dialogContent = {
    icon: warningAlert,
    title: "Activate Event",
    text: "Are you sure you would like to active this event",
    actionLabel: "Yes!",
    actionClass: "green"
};

class EventListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false
        };
    }

    actionDialog() {
        this.props.activeEvent(activeEventRequest.Id, true).then(res => {
            toast(<ToasterMessage text="Changes have been saved!" />, { type: "success" });
            this.cancelDialog();
        });
    }

    cancelDialog() {
        this.setState({ dialogOpen: false });
        activeEventRequest = null;
    }

    eventActive(event) {
        console.log("eventActive", event);
        if (event.Status) return null;

        activeEventRequest = event;
        this.setState({ dialogOpen: true, activeFrameDialog: true });
    }

    renderDialog() {
        const { dialogOpen } = this.state,
            { activeEventRequest } = this.props;
        return dialogOpen ? (
            <Dialog>
                <DialogContent
                    icon={dialogContent.icon}
                    title={dialogContent.title}
                    text={dialogContent.text}
                    actionLabel={dialogContent.actionLabel}
                    actionClass={dialogContent.actionClass}
                    loading={activeEventRequest}
                    action={this.actionDialog.bind(this)}
                    cancel={this.cancelDialog.bind(this)}
                />
            </Dialog>
        ) : null;
    }

    render() {
        const { data, fetching } = this.props.events;
        return (
            <section>
                <div className="flex-start-center flex-wrap flex-align-stretch xs-flex-col xs-align-items-center">
                    {data.map(event => {
                        return <Event key={event.Id} event={event} clickHandler={this.eventActive.bind(this)} />;
                    })}
                </div>

                {fetching ? (
                    <div className="flex-center m-y">
                        <Loading />
                    </div>
                ) : null}

                {this.renderDialog()}
            </section>
        );
    }
}

const mapStateToProps = ({ events }) => ({
    events: events.listing,
    activeEventRequest: events.activeEventRequest
});

const mapDispatchToProps = dispatch => {
    return {
        activeEvent: (id, status) => dispatch(activeEvent(id, status))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventListing);
