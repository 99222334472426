import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import UserRoutes from "../User/router";
import UsersMenus from "../../components/Sidebar/UsersMenus";
import Sidebar from "../../components/Sidebar/Sidebar";
import { menuWhite } from "../../assets/svg-icon";
import { isSSOAuthenticated } from "../../utils/ssoUtils";

import "./Dashboard.scss";
import IconButton from "../../components/button/IconButton/IconButton";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        };
        this.menuToggle = this.menuToggle.bind(this);
    }

    menuToggle() {
        this.setState({
            menuOpen: !this.state.menuOpen
        });
    }

    render() {
        const { isAuthenticated, userAuthenticated } = this.props,
            menuOpen = this.state.menuOpen;

        if (!userAuthenticated && sessionStorage.apiKey) return <Redirect to={`/SSO/${sessionStorage.apiKey}`} />;
        else if (!isAuthenticated && !sessionStorage.apiKey) return <Redirect to="/account/login" />;

        return isAuthenticated || isSSOAuthenticated() ? (
            <div className={`dashboard ${menuOpen ? "sidebar-active" : ""}`}>
                <header className="sidebar-toggle-btn hidden sm-visible">
                    <IconButton icon={menuWhite} clickHandler={this.menuToggle} />
                </header>

                <div className="flex-stretch">
                    <div className="overlay-sidebar" onClick={this.menuToggle}>
                        &nbsp;
                    </div>
                    <Sidebar>
                        <UsersMenus />
                    </Sidebar>
                    <main className="flex-grow main-content">
                        <Switch>
                            {UserRoutes.map((route, index) => {
                                return route.component ? (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={props => <route.component {...props} />}
                                    />
                                ) : null;
                            })}
                            <Redirect to="/dashboard/overview" />
                        </Switch>
                    </main>
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userAuthenticated: state.ssoAuth.userAuthenticated,
    user: state.auth.user
});

export default connect(
    mapStateToProps,
    null
)(Dashboard);
