import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";

import "./TypeHead.scss";

class TypeHead extends Component {
    render() {
        const {
            loadOptions,
            onChange,
            placeholder = "search",
            inputValue = "",
            onInputChange,
            isDisabled,
            classes = "",
            styles
        } = this.props;
        return (
            <fieldset className={`type-head ${classes}`}>
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    placeholder={placeholder}
                    onChange={onChange}
                    defaultOptions
                    inputValue={inputValue}
                    onInputChange={onInputChange}
                    isClearable
                    isSearchable
                    isDisabled={isDisabled}
                    styles={styles}
                />
            </fieldset>
        );
    }
}

export default TypeHead;
