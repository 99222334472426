import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import  './Sidebar.scss';


class SidebarList extends Component {


    render() {
        const {to, icon, hoverIcon,  name, href} = this.props;
        return (
            <li>{href  ?
                <a href={href} className="flex-start-center" target="_blank" rel="noopener noreferrer">
                    <img className="icon default-icon" src={icon}  alt={name} />
                    <img className="icon hover-icon" src={hoverIcon}  alt={name} />
                    <span>{name}</span>
                </a>:
                <NavLink to={to} activeClassName="active" className="flex-start-center">
                    <img className="icon default-icon" src={icon}  alt={name} />
                    <img className="icon hover-icon" src={hoverIcon}  alt={name} />
                    <span>{name}</span>
                </NavLink>}
            </li>
        );
    }
}

export default SidebarList;
