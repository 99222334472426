import {
    AUTH_TOKEN_REQUEST,
    AUTH_TOKEN_SUCCESS,
    AUTH_TOKEN_FAIL,
    SSO_ME_SUCCESS,
    SSO_ME_FAIL,
    CLEAR_SSO_SESSION
} from "../actions";

const initialState = {
    fetching: false,
    userAuthenticated: false,
    token: null,
    apiKey: null,
    error: null,
    user: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_TOKEN_REQUEST:
            return {
                ...state,
                fetching: true,
                error: null
            };
        case AUTH_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.payload,
                apiKey: action.apiKey
            };
        case AUTH_TOKEN_FAIL:
        case SSO_ME_FAIL:
            return {
                ...state,
                fetching: false,
                userAuthenticated: false,
                error: action.payload
            };
        case SSO_ME_SUCCESS:
            return {
                ...state,
                fetching: false,
                userAuthenticated: true,
                user: action.payload
            };
        case CLEAR_SSO_SESSION:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
