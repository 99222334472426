import React, { Component } from "react";
import "./Card.scss";

class Card extends Component {
    render() {
        let { title, value = null, text } = this.props;
        value = value !== null ? value : "-";
        return (
            <div className="card white-box p-xs m-xs">
                <h4 className="uppercase card__title">{title}</h4>
                <span className="card__value block">{value}</span>
                <span className="card__text block">{text}</span>
            </div>
        );
    }
}

export default Card;
