import React, { Component } from "react";
import { Link } from "react-router-dom";

import { IconButton } from "../../button/index";
import defaultImg from "../../../assets/images/img-placeholder.jpg";
import { eventDetailsGray, checkWhite } from "../../../assets/svg-icon";
import { Image } from "../../../components/Image";
import Config from "../../../../config";
import "./Event.scss";

let baseUrl = Config.env().BASE_IMG_PATH;

class Event extends Component {
    render() {
        const { event, clickHandler } = this.props,
            img = event.Frame && event.Frame.Path ? `${baseUrl}${event.Frame.Path}` : defaultImg;
        return (
            <article className={`event-card ${event.Status ? "active" : ""}`}>
                <div className="relative event-card__box">
                    <IconButton
                        classes={`event-card__btn ${event.Status ? "green" : ""}`}
                        icon={checkWhite}
                        clickHandler={() => clickHandler(event)}
                    />
                    <div className="event-card__frame white-box">
                        <div className="event-card__img m-a">
                            <Image src={img} alt="" />
                        </div>
                        <h4 className="event-card__title capitalize">{event.Name}</h4>
                        <Link to={`/dashboard/event/detail/${event.Id}`} className="event-card__link flex-start-center">
                            <img className="event-card__link-icon" src={eventDetailsGray} alt="icon" />
                            <span>Event Details</span>
                        </Link>
                    </div>
                </div>
            </article>
        );
    }
}

export default Event;
