import Data from "./Data/Data";
import Detail from "./Detail/Detail";
import Media from "./Media/Media";
import Template from "./Template/Template";


const routes = [
    // { path: "/dashboard", exact: true, name: "Dashboard", component: Home },
    {
        path: "/dashboard/event/data/:id",
        name: "data",
        component: Data,
    },
    {
        path: "/dashboard/event/detail/:id",
        name: "detail",
        component: Detail,
    },
    {
        path:"/dashboard/event/media/:id",
        name: "media",
        component: Media,
    },
    {
        path:"/dashboard/event/template/:id",
        name: "template",
        component: Template
    }
];

export default routes;
