import { fetchUtil } from "../../utils/fetchUtils";
import { queryParams } from "../../utils/urlUtils";
import { handleFetchError } from "../../utils/errorHandlerUtils";
import Config from "../../../config";
import { errorToaster } from "../../utils/loggerUtils";
import { getToken } from "../../utils/tokenUtils";

// get media in event details
export const EVENT_MEDIA_REQUEST = "EVENT_MEDIA_REQUEST";
export const EVENT_MEDIA_SUCCESS = "EVENT_MEDIA_SUCCESS";
export const EVENT_MEDIA_FAIL = "EVENT_MEDIA_FAIL";

// set selected media for download
export const SET_MEDIA_TO_DOWNLOAD = "SET_MEDIA_TO_DOWNLOAD";
export const CLEAR_MEDIA_TO_DOWNLOAD = "CLEAR_MEDIA_TO_DOWNLOAD";

// clear media state
export const CLEAR_MEDIA_STATE = "CLEAR_MEDIA_STATE";

// get event details by event id
export const EVENT_DETAIL_REQUEST = "EVENT_DETAIL_REQUEST";
export const EVENT_DETAIL_SUCCESS = "EVENT_DETAIL_SUCCESS";
export const EVENT_DETAIL_FAIL = "EVENT_DETAIL_FAIL";

// get event templete
export const EVENT_TEMPLATE_REQUEST = "EVENT_TEMPLATE_REQUEST";
export const EVENT_TEMPLATE_SUCCESS = "EVENT_TEMPLATE_SUCCESS";
export const EVENT_TEMPLATE_FAIL = "EVENT_TEMPLATE_FAIL";

// update event name
export const UPDATE_EVENT_NAME = "UPDATE_EVENT_NAME";

// add a new event
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";

export const EVENT_LISTING_REQUEST = "EVENT_LISTING_REQUEST";
export const EVENT_LISTING_SUCCESS = "EVENT_LISTING_SUCCESS";
export const EVENT_LISTING_FAIL = "EVENT_LISTING_FAIL";
export const CLEAR_EVENT_LISTING = "CLEAR_EVENT_LISTING";
export const ACTIVE_EVENT_REQUEST = "ACTIVE_EVENT_REQUEST";
export const ACTIVE_EVENT_SUCCESS = "ACTIVE_EVENT_SUCCESS";
export const ACTIVE_EVENT_FAIL = "ACTIVE_EVENT_FAIL";
export const GET_ACTIVE_EVENT_REQUEST = "GET_ACTIVE_EVENT_REQUEST";
export const GET_ACTIVE_EVENT_SUCCESS = "GET_ACTIVE_EVENT_SUCCESS";
export const GET_ACTIVE_EVENT_FAIL = "GET_ACTIVE_EVENT_FAIL";
export const GET_ACTIVE_FRAME_REQUEST = "GET_ACTIVE_FRAME_REQUEST";
export const GET_ACTIVE_FRAME_SUCCESS = "GET_ACTIVE_FRAME_SUCCESS";
export const GET_ACTIVE_FRAME_FAIL = "GET_ACTIVE_FRAME_FAIL";
export const ACTIVE_FRAME_REQUEST = "ACTIVE_FRAME_REQUEST";
export const ACTIVE_FRAME_SUCCESS = "ACTIVE_FRAME_SUCCESS";
export const ACTIVE_FRAME_FAIL = "ACTIVE_FRAME_FAIL";
export const DELETE_FRAME_REQUEST = "DELETE_FRAME_REQUEST";
export const DELETE_FRAME_SUCCESS = "DELETE_FRAME_SUCCESS";
export const DELETE_FRAME_FAIL = "DELETE_FRAME_FAIL";

export const addEvent = (bodyData, media) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "event",
        token,
        method: "POST",
        body: JSON.stringify(bodyData)
    })
        .then(res => {
            const { Id } = res;
            return dispatch(uploadMedia(media, Id, 1))
                .then(uploadedData => {
                    dispatch(getEventById(Id)).then(event => {
                        dispatch({ type: ADD_EVENT_SUCCESS, payload: event.Event });
                    });
                    return Promise.resolve(true);
                })
                .catch(err => errorToaster(err));
        })
        .catch(err => {
            if (err.Errors.hasOwnProperty("FromEmail")) {
                const emailError = { Message: err.Errors.FromEmail };
                errorToaster(emailError);
                return;
            }
            errorToaster(err);
        });
};

export const uploadMedia = (data, EventId, IsFrame) => (dispatch, getState) => {
    const body = new FormData();
    const token = getToken();
    body.append("file", data);
    const url = queryParams(`${Config.env().API_URL}media`, { EventId, IsFrame });

    return fetch(url, {
        method: "POST",
        body,
        headers: {
            Authorization: token
        },
        credentials: "include"
    })
        .then(handleFetchError)
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
};

export const getEventMedia = (id, paramObj, key) => (dispatch, getState) => {
    dispatch({ type: EVENT_MEDIA_REQUEST, key });
    const token = getToken();
    fetchUtil({
        url: queryParams(`media/event/${id}`, { Limit: 9, ...paramObj }),
        token
    })
        .then(res => {
            dispatch({ type: EVENT_MEDIA_SUCCESS, payload: { data: res.Files, total: res.TotalFiles, key } });
        })
        .catch(err => {
            dispatch({ type: EVENT_MEDIA_FAIL, payload: { Message: err.Message, key } });
        });
};

export const downloadMedia = (id, params) => (dispatch, getState) => {
    const token = getToken();

    const url = queryParams(`${Config.env().API_URL}media/download/event/${id}`, params);
    return fetch(url, {
        headers: {
            Authorization: token
        },
        credentials: "include"
    })
        .then(res => res.json())
        .then(res => Promise.resolve(res))
        .catch(err => errorToaster(err));
};

export const setMediaToDownload = (ids, type) => {
    return { type: SET_MEDIA_TO_DOWNLOAD, payload: { ids, type } };
};

export const clearMediaToDownload = () => {
    return { type: CLEAR_MEDIA_TO_DOWNLOAD };
};

export const clearMediaState = () => {
    return { type: CLEAR_MEDIA_STATE };
};

export const eventListing = params => (dispatch, getState) => {
    dispatch({ type: EVENT_LISTING_REQUEST });
    const token = getToken();

    fetchUtil({
        url: queryParams("events", { ...params }),
        token
    })
        .then(res => {
            dispatch({ type: EVENT_LISTING_SUCCESS, payload: res, page: params.Page });
        })
        .catch(err => {
            dispatch({ type: EVENT_LISTING_FAIL, payload: err.Message });
        });
};

export const activeEvent = (id, status) => (dispatch, getState) => {
    dispatch({ type: ACTIVE_EVENT_REQUEST });
    const body = {
        Id: id,
        Status: status ? 1 : 0
    };

    return dispatch(updateEvent(body))
        .then(res => {
            Promise.resolve(res);
            dispatch({ type: ACTIVE_EVENT_SUCCESS, payload: body });
            if (window.location.pathname === "/dashboard/overview") {
                dispatch(getActiveEvent());
            }
        })
        .catch(err => {
            Promise.reject(err);
            errorToaster(err);
            dispatch({ type: ACTIVE_EVENT_FAIL });
        });
};

export const clearEventListing = () => {
    return {
        type: CLEAR_EVENT_LISTING
    };
};

export const getEventDetails = id => (dispatch, getState) => {
    dispatch({ type: EVENT_DETAIL_REQUEST });
    dispatch(getEventById(id))
        .then(res => {
            dispatch({ type: EVENT_DETAIL_SUCCESS, payload: res });
        })
        .catch(err => {
            dispatch({ type: EVENT_DETAIL_FAIL, payload: err.Message });
        });
};

export const getEventById = id => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `event/${id}`,
        token
    })
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
};

export const updateEvent = bodyData => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        method: "PUT",
        url: "event",
        token,
        body: JSON.stringify(bodyData)
    })
        .then(res => {
            return Promise.resolve(res);
        })
        .catch(err => {
            errorToaster(err);
            return Promise.reject(err.Message);
        });
};

export const updateCurrentEvent = (Id, Name, FrameEnabled) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch(updateEvent({ Id, Name, FrameEnabled }))
            .then(res => {
                dispatch({ type: UPDATE_EVENT_NAME, Name, FrameEnabled });
                resolve(true);
            })
            .catch(err => {
                reject(false);
            });
    });
};

export const getEventTemplate = id => (dispatch, getState) => {
    dispatch({ type: EVENT_TEMPLATE_REQUEST });
    const token = getToken();
    fetchUtil({
        url: `template/${id}`,
        token
    })
        .then(res => {
            dispatch({ type: EVENT_TEMPLATE_SUCCESS, payload: res });
        })
        .catch(err => {
            errorToaster(err);
            dispatch({ type: EVENT_TEMPLATE_FAIL });
        });
};

export const updateEventTemplate = bodyData => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        method: "PUT",
        url: "template",
        token,
        body: JSON.stringify(bodyData)
    })
        .then(res => {
            return Promise.resolve(res);
        })
        .catch(err => {
            if (err.Errors.hasOwnProperty("FromEmail")) {
                const emailError = { Message: err.Errors.FromEmail };
                errorToaster(emailError);
            } else {
                errorToaster(err);
            }
            return Promise.reject(err.Message);
        });
};

export const getActiveEvent = () => (dispatch, getState) => {
    dispatch({ type: GET_ACTIVE_EVENT_REQUEST });
    return new Promise((resolve, reject) => {
        const token = getToken();
        fetchUtil({
            url: "current-event",
            token
        })
            .then(res => {
                resolve(res);
                if (res.Event && res.Event.Id) {
                    dispatch(getFrames(res.Event.Id));
                }
                dispatch({ type: GET_ACTIVE_EVENT_SUCCESS, payload: res.Event });
            })
            .catch(err => {
                reject(err);
                errorToaster(err);
                dispatch({ type: GET_ACTIVE_EVENT_FAIL });
            });
    });
};

export const getFrames = eventId => (dispatch, getState) => {
    dispatch({ type: GET_ACTIVE_FRAME_REQUEST });
    return new Promise((resolve, reject) => {
        const token = getToken();
        fetchUtil({
            url: `media/frame/${eventId}`,
            token
        })
            .then(res => {
                resolve(res);
                dispatch({ type: GET_ACTIVE_FRAME_SUCCESS, payload: res });
            })
            .catch(err => {
                reject(err);
                errorToaster(err);
                dispatch({ type: GET_ACTIVE_FRAME_FAIL });
            });
    });
};

export const activeFrame = (Id, ActiveFrameId) => (dispatch, getState) => {
    dispatch({ type: ACTIVE_FRAME_REQUEST });
    const token = getToken(),
        body = {
            Id,
            ActiveFrameId
        };
    const frame = getState().events.activeEvent.frames.find(val => val.Id === ActiveFrameId);
    const selectedFrame = { ...frame };
    return new Promise((resolve, reject) => {
        fetchUtil({
            method: "PUT",
            url: "event",
            token,
            body: JSON.stringify(body)
        })
            .then(res => {
                resolve(res);
                dispatch({ type: ACTIVE_FRAME_SUCCESS, payload: body, selectedFrame });
            })
            .catch(err => {
                reject(err);
                errorToaster(err);
                dispatch({ type: ACTIVE_FRAME_FAIL });
            });
    });
};

export const deleteFrame = (id, frameId) => (dispatch, getState) => {
    dispatch({ type: DELETE_FRAME_REQUEST });
    return new Promise((resolve, reject) => {
        const token = getToken();
        fetchUtil({
            method: "DELETE",
            url: `media/frame/${frameId}`,
            token: token
        })
            .then(res => {
                resolve(res);
                dispatch({ type: DELETE_FRAME_SUCCESS, payload: frameId });
            })
            .catch(err => {
                reject(err);
                errorToaster(err);
                dispatch({ type: DELETE_FRAME_FAIL });
            });
    });
};
