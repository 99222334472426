import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { signOut } from "../../redux/actions";

class Logout extends React.Component {
    componentDidMount() {
        this.props.signOut().then(res => {
            this.props.history.replace("/account/login");
        });
    }
    render() {
        return <React.Fragment />;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch(signOut())
    };
};

export default connect(
    null,
    mapDispatchToProps
)(withRouter(Logout));
