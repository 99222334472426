import React, { Component } from "react";
import { connect } from "react-redux";

import {
    getEventContactList,
    downloadContactList,
    setContactsToDownload,
    clearContactsToDownload,
    clearContactState
} from "../../../../../redux/actions";
import { TabTable } from "../../../../../components/Table";

class Data extends Component {
    getContactList = (paramObj, tabName) => {
        const id = Number(this.props.match.params.id);
        this.props.getEventContactList(id, paramObj, tabName); //TODO remove the hardcoded id
    };

    onClearContactState = () => {
        this.props.clearContactState("eventContacts");
    };

    render() {
        const { emailList, phoneList, tabState, setContactsToDownload, clearContactsToDownload, eventData } = this.props;
        const memberType = eventData.Event ? eventData.Event.MembershipStatus : 1;
        const membersCollecting = memberType === 1 ? true : false;
        return (
            <section className="event-media">
                <TabTable
                    emailList={emailList}
                    phoneList={phoneList}
                    tabState={tabState}
                    getContactList={this.getContactList}
                    setContactsToDownload={setContactsToDownload}
                    clearContactsToDownload={clearContactsToDownload}
                    clearContactState={this.onClearContactState}
                    tableType="event"
                    collectingMembers={membersCollecting}
                />
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        tabState: state.contact.eventContacts.tabState,
        emailList: state.contact.eventContacts.emailList,
        phoneList: state.contact.eventContacts.phoneList,
        eventData: state.events.eventDetails.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getEventContactList: (id, paramsObj, key) => dispatch(getEventContactList(id, paramsObj, key)),
        downloadContactList: paramsObj => dispatch(downloadContactList(paramsObj)),
        setContactsToDownload: (ids, type, allDownload) => dispatch(setContactsToDownload(ids, type, allDownload)),
        clearContactsToDownload: () => dispatch(clearContactsToDownload()),
        clearContactState: key => dispatch(clearContactState(key))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Data);
