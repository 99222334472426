import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./TabButton.scss";

class TabButton extends Component {
    renderIcon() {
        const { icon, activeIcon, iconWidth } = this.props,
        width = iconWidth ? {width : iconWidth} : {};
        return icon ? (
            <span className="icon inline-flex-center m-r-xs" style={width}>
                {" "}
                <img className="default-icon" src={icon} alt="" /> <img className="active-icon" src={activeIcon} alt="" />
            </span>
        ) : null;
    }

    render() {
        let { label = null, classes = "primary-btn", to, clickHandler } = this.props;
        label = label !== null ? label : <span style={{ visibility: "hidden" }}>0</span>;
        return to ? (
            <Link to={to} className={`tab-button bold ${classes}`} onClick={clickHandler}>
                {this.renderIcon()}
                <span className="secondary-font">{label}</span>
            </Link>
        ) : (
            <button className={`tab-button bold ${classes}`} onClick={clickHandler}>
                {this.renderIcon()}
                <span className="secondary-font">{label}</span>
            </button>
        );
    }
}

export default TabButton;
