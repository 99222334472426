import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./TextArea.scss";

const defaultHeight = `250px`;
class TextArea extends Component {
    renderLimit() {
        const { maxLength, value } = this.props;
        return maxLength ? (
            <p className="limit-section flex-sb">
                <Link to="#" className="hidden a">
                    http://booth.dr/abc345
                </Link>
                <p className="short-code">
                    <span className="a border-none">https://drbooth.co/m/a94d2e0</span>
                </p>
                <span className="light-gray-text">{maxLength - value.length} Characters</span>
            </p>
        ) : null;
    }

    render() {
        const { height = defaultHeight, maxLength = "", value, onChange } = this.props;
        return (
            <fieldset className="text-area">
                <textarea style={{ height }} maxLength={maxLength} value={value} onChange={onChange} />
                {this.renderLimit()}
            </fieldset>
        );
    }
}

export default TextArea;
