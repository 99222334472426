import React, { Component } from "react";
import "./Sidebar.scss";

class Sidebar extends Component {
    render() {
        const { children } = this.props;
        return (
            <aside className="sidebar relative">
                <div className="sidebar-fixed-container fancy-scroll">
                    <div className="sidebar-container">{children}</div>
                </div>
            </aside>
        );
    }
}

export default Sidebar;
