import { fetchUtil } from "../../utils/fetchUtils";
import { getToken } from "../../utils/tokenUtils";

export const ANALYTICS_DATA_REQUEST = "ANALYTICS_DATA_REQUEST";
export const ANALYTICS_DATA_SUCCESS = "ANALYTICS_DATA_SUCCESS";
export const ANALYTICS_DATA_FAIL = "ANALYTICS_DATA_FAIL";

export const getAnalyticsData = () => (dispatch, getState) => {
    dispatch({ type: ANALYTICS_DATA_REQUEST });
    const token = getToken();
    fetchUtil({
        url: `analytics`,
        token
    })
        .then(res => {
            dispatch({ type: ANALYTICS_DATA_SUCCESS, payload: res });
        })
        .catch(err => {
            dispatch({ type: ANALYTICS_DATA_FAIL, payload: err.Message });
        });
};
