import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "./Detail.scss";
import { updateCurrentEvent } from "../../../../../redux/actions";
import { LayoutLoading } from "../../../../../components/Loading";
import Config from "../../../../../../config";
import imagePlaceholder from "../../../../../assets/images/img-placeholder.jpg";
import InputField from "../../../../../components/input/InputField/InputField";
import Button from "../../../../../components/button/Button/Button";
import Card from "../../../../../components/Card/Card";
import ToasterMessage from "../../../../../components/ToasterMessage/ToasterMessage";
import { Image } from "../../../../../components/Image";
import { ToggleButton } from "../../../../../components/button/ToggleButton";

const FRAME_ENABLED = {
    1: true,
    0: false
};

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventName: "",
            showFrame: false,
            isLoading: false
        };
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (prevProps.data !== data) {
            if (data.hasOwnProperty("Event"))
                this.setState({ eventName: data.Event.Name, showFrame: FRAME_ENABLED[data.Event.FrameEnabled] });
        }
    }

    componentDidMount() {
        const { data } = this.props;
        if (data.hasOwnProperty("Event")) {
            this.setState({ eventName: data.Event.Name, showFrame: FRAME_ENABLED[data.Event.FrameEnabled] });
        }
    }

    submit(e) {
        e.preventDefault();
        const { updateCurrentEvent } = this.props;
        const { eventName, showFrame } = this.state;
        const id = Number(this.props.match.params.id);
        const FrameEnabled = showFrame ? 1 : 0;
        if (eventName.length !== 0) {
            this.setState({ isLoading: true });
            updateCurrentEvent(id, eventName, FrameEnabled).then(res => {
                toast(<ToasterMessage text="Event updated successfully" />, { type: "success" });
                this.setState({ isLoading: false });
            });
        }
    }

    handleNameChange = e => {
        this.setState({ eventName: e.target.value });
    };

    handleToggleBtn = e => {
        this.setState({ showFrame: e.target.checked });
    };

    render() {
        const { data, fetching } = this.props;
        const { eventName, isLoading, showFrame } = this.state;
        const image =
            data.Event && data.Event.Frame !== null ? `${Config.env().BASE_IMG_PATH}${data.Event.Frame.Path}` : imagePlaceholder;
        return (
            <section className="details p-x p-t-xs p-b">
                <div className="flex-sb xs-flex-wrap">
                    <div className="flex-60 xs-flex-100 xs-m-a event-img relative">
                        <Image src={image} alt="" />
                        {fetching && <LayoutLoading classes="black" />}
                    </div>
                    <div className="flex-40 xs-flex-100 event-from">
                        <form onSubmit={this.submit.bind(this)}>
                            <InputField
                                classes="primary"
                                type="text"
                                label="EVENT NAME"
                                value={eventName}
                                onChange={this.handleNameChange}
                                placeholder="Event Name"
                            />
                            <ToggleButton label="Show Frame" checked={showFrame} changeHandler={this.handleToggleBtn} />
                            <Button type="submit" label="Update" classes="green" loading={isLoading} lodingClass="green" />
                        </form>
                    </div>
                </div>

                <div className="flex-center m-t-md xs-flex-col xs-align-items-center">
                    <Card title="Media" value={data.TotalMediaCount} text="Total Captured" />
                    <Card title="Emails" value={data.TotalEmailCount} text="Email Addresses Collected" />
                    <Card title="Numbers" value={data.TotalSMSCount} text="Phone Numbers Collected" />
                </div>
            </section>
        );
    }
}

export const mapStateToProps = state => {
    return {
        data: state.events.eventDetails.data,
        fetching: state.events.eventDetails.fetching
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        updateCurrentEvent: (Id, Name, FrameEnabled) => dispatch(updateCurrentEvent(Id, Name, FrameEnabled))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Detail);
