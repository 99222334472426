import { store } from "../redux/store";
import { isSSOAuthenticated } from "./ssoUtils";

export const getToken = () => {
    const { auth, ssoAuth } = store.getState();
    if (isSSOAuthenticated()) {
        return ssoAuth.token;
    }
    return auth.user.Token || localStorage.RBToken;
};
