import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Account } from "./app/views/Account";
import Dashboard from "./app/views/Dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import Logout from "./app/components/Logout/Logout";
import SignIn from "./app/views/Account/SignIn/SignIn";
import VerifyPage from "./app/views/Account/VerifyPage/VefiryPage";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

class App extends Component {
    render() {
        return (
            <div id="range-booth">
                <ToastContainer autoClose={3000} />
                <BrowserRouter>
                    <Switch>
                        <Route path="/account" name="account" component={Account} />
                        <Route path="/dashboard" name="dashboard" component={Dashboard} />
                        <Route path="/SSO/:apiKey" component={SignIn} />
                        <Route path="/verify/:token" component={VerifyPage} />
                        <Route path="/logout" name="logout" component={Logout} />
                        <Redirect to="/account/login" />
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
