import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";

import Card from "../../../components/Card/Card";
import { getAnalyticsData } from "../../../redux/actions";
import Spinner from "../../../components/Loading/Spinner/Spinner";

const options = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false
  },
  barThickness: 1,
  maxBarThickness: "1px",
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          display: true
        },
        ticks: {
          min: 0,
          suggestedMax: 10,
          precision: 0
        }
      }
    ]
  }
};

class Analytics extends Component {
  componentDidMount() {
    this.props.getAnalyticsData();
  }

  getChartData = () => {
    const { mediaCaptureData } = this.props;
    const dailyCaptureData = [];
    Object.keys(mediaCaptureData)
      .reverse()
      .forEach(key => {
        let obj = mediaCaptureData[key];
        dailyCaptureData.push(obj["TotalMediaCount"]);
      });
    return {
      labels: Object.keys(mediaCaptureData).reverse(),
      datasets: [
        {
          responsive: true,
          backgroundColor: "#4db761",
          borderWidth: 0,
          hoverBackgroundCor: "red",
          hoverBorderColor: "blue",
          barThickness: 1,
          maxBarThickness: 1,
          data: dailyCaptureData
        }
      ]
    };
  };

  render() {
    const { loading, TotalEmail, TotalSMS, TotalMedia } = this.props;
    return (
      <section className="white-box p">
        <div>
          <h3 className="center m-b"> Media Captured </h3>

          <div className="flex-100">
            {loading ? (
              <div className="flex-center p-y-xlg">
                <Spinner classes="green large"/>
              </div>
            ) : (
              <Bar
                data={this.getChartData}
                height={250}
                width={100}
                options={options}
              />
            )}
          </div>
        </div>

        <div className="flex-center m-t-md xs-flex-col xs-align-items-center">
          <Card title="Media" value={TotalMedia} text="Total Captured" />
          <Card title="Emails" value={TotalEmail} text="Email Addresses Collected" />
          <Card title="Numbers" value={TotalSMS} text="Phone Numbers Collected" />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    mediaCaptureData: state.analytics.data.DailyMediaCapturedData,
    TotalEmail: state.analytics.data.TotalEmail,
    TotalSMS: state.analytics.data.TotalSMS,
    TotalMedia: state.analytics.data.TotalMedia,
    loading: state.analytics.fetching
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAnalyticsData: () => dispatch(getAnalyticsData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Analytics);
