import React, { Component } from "react";
import { connect } from "react-redux";
import { DefaultPlayer as Video } from "react-html5video";

import "./Media.scss";
import "react-html5video/dist/styles.css";
import Config from "../../../../../../config";
import { getEventMedia, clearMediaState, setMediaToDownload, clearMediaToDownload } from "../../../../../redux/actions";
import TabButton from "../../../../../components/button/TabButton/TabButton";
import { imageGreen, imageBlack, videoGreen, videoBlack, gifGreen, gifBlack } from "../../../../../assets/svg-icon";
import CheckBox from "../../../../../components/input/CheckBox/CheckBox";
import { MediaTypes } from "../../../../../config/constants";
import { Spinner } from "../../../../../components/Loading";
import { Image } from "../../../../../components/Image";

const TABNAME = {
    IMAGES: "images",
    VIDEOS: "videos",
    GIFS: "gifs"
};

class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: TABNAME.IMAGES,
            images: {
                data: [],
                pageNo: 1
            },
            videos: {
                data: [],
                pageNo: 1
            },
            gifs: {
                data: [],
                pageNo: 1
            }
        };
        this.ScrollDiv = React.createRef();
    }

    componentDidMount() {
        const { getEventMedia } = this.props;
        const { images, videos, gifs } = this.state;
        const id = Number(this.props.match.params.id);
        const type = MediaTypes.IMAGES;
        getEventMedia(id, { FilterType: MediaTypes[TABNAME.IMAGES.toUpperCase()], Page: images.pageNo }, TABNAME.IMAGES);
        getEventMedia(id, { FilterType: MediaTypes[TABNAME.VIDEOS.toUpperCase()], Page: videos.pageNo }, TABNAME.VIDEOS);
        getEventMedia(id, { FilterType: MediaTypes[TABNAME.GIFS.toUpperCase()], Page: gifs.pageNo }, TABNAME.GIFS);
        this.props.setMediaToDownload([], type);
        this.props.clearMediaToDownload();
    }

    componentDidUpdate(prevProps) {
        const { currentTab } = this.state;
        if (this.props.media[currentTab] !== prevProps.media[currentTab]) {
            const data = this.props.media[currentTab].data.map(value => {
                return { ...value, checked: false };
            });
            this.setState({ [currentTab]: { ...this.state[currentTab], data } });
        }
    }

    componentWillUnmount() {
        this.props.clearMediaState();
    }

    clickHandler(tabName) {
        const { media } = this.props;
        this.ScrollDiv.current.scrollTop = 0;
        const type = MediaTypes[tabName.toUpperCase()];
        if (media[tabName].data.length !== 0) {
            const data = media[tabName].data.map(values => {
                return { ...values, checked: false };
            });
            this.setState({ [tabName]: { ...this.state[tabName], data } });
        }
        this.setState({ currentTab: tabName });
        this.props.setMediaToDownload([], type);
        this.props.clearMediaToDownload();
    }

    checkHandler(index) {
        let { currentTab } = this.state;
        const userIds = [];
        let data = this.state[currentTab].data;
        const type = MediaTypes[currentTab.toUpperCase()];
        // data[index].checked = e.target.checked;
        data[index].checked = !data[index].checked;
        data.forEach(items => {
            if (items.checked) {
                userIds.push(items.Id);
            }
        });

        this.setState({ [currentTab]: { ...this.state[currentTab], data } });
        this.props.setMediaToDownload(userIds, type);
    }

    handleScroll = e => {
        const { currentTab } = this.state;
        const { media, getEventMedia } = this.props;
        const id = Number(this.props.match.params.id);
        const FilterType = MediaTypes[currentTab.toUpperCase()];
        const page = this.state[currentTab].pageNo + 1;
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
        console.log("SCHEIGHT" + e.target.scrollHeight);
        console.log("SCTOP" + e.target.scrollTop);
        console.log("CHEIGHT" + e.target.clientHeight);
        console.log(bottom);
        console.log(page);
        if (bottom && !media[currentTab].fetching && media[currentTab].dataAvailable) {
            console.log("Loading MORE ");
            getEventMedia(id, { FilterType, Page: page }, currentTab);
            this.setState({ [currentTab]: { ...this.state[currentTab], pageNo: page } });
        }
    };

    renderMedia() {
        const { currentTab } = this.state;
        const data = this.state[currentTab].data;
        return data.map((media, index) => {
            return (
                <div key={index} className="media-box">
                    <div className="media-box__container pointer">
                        <div
                            style={{ width: "110px", height: "90px", position: "absolute", zIndex: "2" }}
                            onClick={() => this.checkHandler(index)}>
                            <CheckBox
                                checked={media.checked}
                                changeHandler={() => this.checkHandler(index)}
                                id={media.id}
                                classes="check-event"
                            />
                        </div>
                        {currentTab === TABNAME.VIDEOS ? (
                            <Video
                                muted
                                preload="metadata"
                                className="media-image m-a inline-flex box-size"
                                controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}>
                                <source src={`${Config.env().BASE_IMG_PATH}${media.Path}`} type="video/mp4" />
                            </Video>
                        ) : currentTab === TABNAME.IMAGES ? (
                            <div className="media-image m-a inline-flex">
                                <Image src={`${Config.env().BASE_IMG_PATH}${media.Path}`} alt="event" />
                            </div>
                        ) : (
                            <div className="gif media-image m-a inline-flex">
                                <div className="gif">
                                    <Image src={`${Config.env().BASE_IMG_PATH}${media.Path}`} alt="event" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }

    render() {
        const { currentTab } = this.state;
        const { media } = this.props;
        const data = this.state[currentTab].data;
        const loading = media[currentTab].fetching;

        return (
            <section className="event-media m-x p-b">
                <div className="flex-center">
                    <TabButton
                        clickHandler={this.clickHandler.bind(this, TABNAME.IMAGES)}
                        label={media[TABNAME.IMAGES].total}
                        icon={imageBlack}
                        activeIcon={imageGreen}
                        classes={currentTab === TABNAME.IMAGES ? "active" : ""}
                    />
                    <TabButton
                        clickHandler={this.clickHandler.bind(this, TABNAME.VIDEOS)}
                        label={media[TABNAME.VIDEOS].total}
                        icon={videoBlack}
                        activeIcon={videoGreen}
                        classes={currentTab === TABNAME.VIDEOS ? "active" : ""}
                    />
                    <TabButton
                        clickHandler={this.clickHandler.bind(this, TABNAME.GIFS)}
                        label={media[TABNAME.GIFS].total}
                        icon={gifBlack}
                        activeIcon={gifGreen}
                        classes={currentTab === TABNAME.GIFS ? "active" : ""}
                    />
                </div>

                <div
                    className="flex-center flex-wrap xs-flex-col fancy-scroll event-media-section"
                    ref={this.ScrollDiv}
                    onScroll={this.handleScroll}>
                    {data.length !== 0 && this.renderMedia()}
                    {!loading && data.length === 0 && <div className="flex-center p-y-xlg">no data avilable</div>}
                    {loading && data.length > 0 ? (
                        <div className="flex-center p-y-sm">
                            <Spinner classes="green small" />
                        </div>
                    ) : null}
                    {loading && data.length === 0 ? (
                        <div className="flex-center p-y-xlg">
                            <Spinner classes="green" />
                        </div>
                    ) : null}
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        media: state.events.media
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getEventMedia: (id, paramObj, key) => dispatch(getEventMedia(id, paramObj, key)),
        clearMediaState: () => dispatch(clearMediaState()),
        setMediaToDownload: (ids, type) => dispatch(setMediaToDownload(ids, type)),
        clearMediaToDownload: () => dispatch(clearMediaToDownload())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Media);
