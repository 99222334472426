import React, { Component } from "react";
import  './Spinner.scss';

class Spinner extends Component {
    render() {
       const {classes=''} = this.props;
        return (
                <span className={`spinner-loading ${classes}`}><span></span></span>
        );
    }
}

export default Spinner;
