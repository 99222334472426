import React, { Component } from "react";
import { connect } from "react-redux";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { toast } from "react-toastify";
import { stateToHTML } from "draft-js-export-html";

import "./Template.scss";
import { LayoutLoading } from "../../../../../components/Loading";
import { getEventTemplate, updateEventTemplate } from "../../../../../redux/actions";
import { InputField, RichTextEditor, TextArea } from "../../../../../components/input";
import Button from "../../../../../components/button/Button/Button";
import ToasterMessage from "../../../../../components/ToasterMessage/ToasterMessage";
import { validateNonGSMCharacters } from "../../../../../utils/validateHelperUtils";

class Template extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromName: "",
            fromEmail: "",
            subjectLine: "",
            smsContent: "",
            editorState: EditorState.createEmpty(),
            smsLoading: false,
            emailLoading: false,
            emailError: false,
            smsTemplateError: false
        };
    }

    componentDidMount() {
        const id = Number(this.props.match.params.id);
        this.props.getEventTemplate(id);
    }

    componentDidUpdate(prevProps) {
        const { templateData } = this.props;
        if (prevProps.templateData !== templateData) {
            if (Object.keys(templateData).length !== 0) {
                const { EmailTemplate, SMSTemplate } = templateData;
                this.setState({
                    fromName: EmailTemplate.FromName,
                    fromEmail: EmailTemplate.FromEmail,
                    subjectLine: EmailTemplate.Subject,
                    smsContent: SMSTemplate.Content,
                    editorState: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(`<p>${EmailTemplate.Content}</p>`))
                    )
                });
            }
        }
    }

    handleInputChange = (e, key) => {
        this.setState({ [key]: e.target.value });
        if (key === "fromEmail" && this.state.emailError) {
            this.setState({ emailError: false });
        }
    };

    handleSmsTemplateChange = e => {
        this.setState({ smsContent: e.target.value });

        if (validateNonGSMCharacters(e.target.value)) {
            this.setState({ smsTemplateError: true });
        } else {
            this.setState({ smsTemplateError: false });
        }
    };

    handleRichTexboxChange = editorState => {
        this.setState({
            editorState
        });
    };

    handleSMSUpdate = e => {
        e.preventDefault();
        const { smsContent } = this.state;
        const {
            templateData: { SMSTemplate }
        } = this.props;
        if (this.state.smsTemplateError) {
            return;
        }
        const bodyData = {
            Id: SMSTemplate.Id,
            Content: smsContent
        };
        if (smsContent.length !== 0) {
            this.setState({ smsLoading: true });
            this.props
                .updateEventTemplate(bodyData)
                .then(res => {
                    toast(<ToasterMessage text="SMS Template updated sucessfully" />, { type: "success" });
                    this.setState({ smsLoading: false });
                })
                .catch(err => this.setState({ emailLoading: false }));
        }
    };

    handleEmailUpdate = e => {
        e.preventDefault();
        const { fromName, fromEmail, subjectLine, editorState } = this.state;
        const {
            templateData: { EmailTemplate }
        } = this.props;
        const emailContent = stateToHTML(editorState.getCurrentContent());
        let emailRegex = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

        if (fromName.length === 0 || fromEmail.length === 0 || subjectLine.length === 0 || emailContent.length === 0) {
            return;
        } else if (!emailRegex.test(fromEmail)) {
            this.setState({ emailError: true });
            return;
        }
        this.setState({ emailLoading: true });
        const bodyData = {
            Id: EmailTemplate.Id,
            FromName: fromName,
            FromEmail: fromEmail,
            Subject: subjectLine,
            Content: emailContent
        };
        this.props
            .updateEventTemplate(bodyData)
            .then(res => {
                toast(<ToasterMessage text="Email Template updated sucessfully" />, { type: "success" });
                this.setState({ emailLoading: false });
            })
            .catch(err => this.setState({ emailLoading: false }));
    };

    renderEmailTemplate() {
        const { fromName, fromEmail, subjectLine, editorState, emailLoading, emailError } = this.state;
        return (
            <form>
                <InputField
                    type="text"
                    name="name"
                    placeHolder="From Name"
                    classes="primary m-b-xs"
                    value={fromName}
                    onChange={e => this.handleInputChange(e, "fromName")}
                />
                <InputField
                    type="email"
                    name="email"
                    placeHolder="From Email"
                    classes="primary m-b-xs"
                    error={emailError}
                    value={fromEmail}
                    onChange={e => this.handleInputChange(e, "fromEmail")}
                />
                <InputField
                    type="text"
                    name="sub"
                    placeHolder="Subject Line"
                    classes="primary m-b-xs"
                    value={subjectLine}
                    onChange={e => this.handleInputChange(e, "subjectLine")}
                />
                <RichTextEditor editorState={editorState} onEditorStateChange={this.handleRichTexboxChange} />
                <Button classes="green m-t-xs" label="Update" clickHandler={this.handleEmailUpdate} loading={emailLoading} />
            </form>
        );
    }

    renderSmsTemplate() {
        const { smsContent, smsLoading, smsTemplateError } = this.state;
        return (
            <form>
                <div className="flex-100">
                    <TextArea maxLength={150} value={smsContent} onChange={this.handleSmsTemplateChange} />
                </div>
                <Button
                    classes={smsTemplateError ? "disabled" : "green"}
                    label="Update"
                    clickHandler={this.handleSMSUpdate}
                    loading={smsLoading}
                />
                {smsTemplateError && (
                    <div className="event-template__error">
                        Emojis are currently unsupported for SMS Templates and could cause issues for the recipient, please remove
                        the emoji for optimal deliverability.
                    </div>
                )}
            </form>
        );
    }

    render() {
        const { fetching } = this.props;
        return (
            <section className="event-template m-x p-b relative">
                <div className="flex-sb xs-flex-wrap">
                    <div className="flex-50 xs-flex-100 m-r xs-m-a">
                        <h3 className="heading bold m-y-xs">Email Template</h3>
                        {this.renderEmailTemplate()}
                    </div>
                    <div className="flex-50 xs-flex-100 m-l xs-m-a">
                        <h3 className="heading bold m-y-xs">SMS Template</h3>
                        {this.renderSmsTemplate()}
                    </div>
                </div>
                {fetching && <LayoutLoading classes="black" />}
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        templateData: state.events.eventTemplate.data,
        fetching: state.events.eventTemplate.fetching
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getEventTemplate: id => dispatch(getEventTemplate(id)),
        updateEventTemplate: bodyData => dispatch(updateEventTemplate(bodyData))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Template);
