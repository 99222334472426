import { AUTH_FAIL } from "../redux/actions";
import { store } from "../redux/store";

import { toast } from "react-toastify";
import { isSSOAuthenticated } from "./ssoUtils";

export const handleFetchError = async res => {
    if (res.status >= 400 && res.status < 600) {
        if ((store.getState().auth.isAuthenticated || isSSOAuthenticated()) && res.status === 401) {
            toast.error("Your Session has been Expired!");
            localStorage.clear();
            store.dispatch({ type: AUTH_FAIL });
            return;
        }
        const response = await res.json();
        let exp = {
            Message: response.Message,
            Status: res.status,
            ...(response.Errors && { Errors: response.Errors })
        };
        throw exp;
    }
    return res.json();
};
