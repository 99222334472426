import { fetchUtil } from "../../utils/fetchUtils";
import { logger, errorToaster } from "../../utils/loggerUtils";
import { AuthenticationCodes } from "../../config/constants";
import { persistor } from "../store";

// Login actions
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_CODE = "SET_CODE";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_FAIL = "ME_FAIL";
export const CHANGE_VIEW = "CHANGE_VIEW";
export const SIGN_OUT = "SIGN_OUT";
export const FETCHING = "FETCHING";

const saveToLocalStorage = res => {
    if (res.Code !== AuthenticationCodes.ALREADY_REGISTERED && res.Code !== AuthenticationCodes.INVALID_TOKEN) {
        localStorage.RBToken = res.Token;
        localStorage.RBCode = res.Code;
        // sessionStorage.removeItem("apiKey");
    }
};

export const setCode = code => ({
    type: SET_CODE,
    payload: parseInt(code, 10)
});

export const login = (email, password) => dispatch => {
    dispatch({ type: LOGIN_REQUEST });
    dispatch({ type: FETCHING, payload: true });
    const body = JSON.stringify({
        Email: email,
        Password: password
    });
    fetchUtil({
        url: "login",
        method: "POST",
        body
    })
        .then(res => {
            saveToLocalStorage(res);

            if (res.Code === AuthenticationCodes.USER_LOGIN_SUCCESS) {
                dispatch(getMe());
            }
        })
        .catch(err => {
            errorToaster(err);
            logger(err);
            dispatch({ type: LOGIN_FAIL, payload: err.Message });
            dispatch({ type: FETCHING, payload: false });
        });
};

export const getMe = () => dispatch => {
    return new Promise((resolve, reject) => {
        if (localStorage.RBToken !== undefined) {
            dispatch({ type: FETCHING, payload: true });
            fetchUtil({
                url: "me",
                token: localStorage.RBToken
            })
                .then(res => {
                    dispatch({
                        type: ME_SUCCESS,
                        payload: res.User,
                        Token: localStorage.RBToken,
                        code: localStorage.RBCode
                    });
                    resolve(res);
                })
                .catch(err => {
                    errorToaster(err);
                    logger(err);
                    dispatch({ type: ME_FAIL, payload: err });
                    reject(err);
                })
                .finally(_ => {
                    dispatch({ type: FETCHING, payload: false });
                    dispatch(setCode(localStorage.RBCode));
                });
        } else if (localStorage.RBToken !== undefined) {
            dispatch(setCode(localStorage.RBCode));
            dispatch({ type: FETCHING, payload: false });
            reject(false);
        } else {
            dispatch({ type: ME_FAIL, payload: "Authorization required." });
            dispatch({ type: FETCHING, payload: false });
            reject(false);
        }
    });
};

export const changeView = (isAdminView, redirectUrl) => {
    return {
        type: CHANGE_VIEW,
        payload: isAdminView,
        redirectUrl
    };
};

export const forgotPassword = email => dispatch => {
    const body = JSON.stringify({
        Email: email
    });
    return fetchUtil({
        url: "auth/password/forgot",
        method: "POST",
        body
    });
};

export const signOut = () => (dispatch, getState) => {
    const token = getState().auth.user.Token || localStorage.RBToken;
    return fetchUtil({
        url: "signout",
        method: "POST",
        token
    })
        .then(res => {
            if (res) {
                persistor.purge().then(res => {
                    dispatch({ type: SIGN_OUT });
                    localStorage.clear();
                    sessionStorage.clear();
                    Promise.resolve(true);
                });
            }
        })
        .catch(err => {
            logger(err);
        });
};
