import React, { Component } from "react";
import  './IconButton.scss';


class IconButton extends Component {



    render() {
        const {
            icon = 'A',
            classes = '',
            clickHandler,
            disabled = false,
        } = this.props;
        return (
            <button disabled={disabled} className={`icon-btn ${classes}` }  onClick={clickHandler}>
                <img className="icon" src={icon} alt="icon" />
             </button>
        );
    }
}

export default IconButton;
