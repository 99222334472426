import React, { Component } from "react";
import  './DropdownButton.scss';

import {options} from "../../../assets/svg-icon"
import IconButton from "../IconButton/IconButton";


class DropdownButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active : false
        };
        this.documentClick = this.documentClick.bind(this);
    }


    onClickHandler(){
        this.setState({active : true});
        this.documentListerBind();
    }

    documentListerBind(){
        document.getElementById("root").addEventListener('click', this.documentClick, false);
    }

    documentListerUnbind(){
        document.getElementById("root").removeEventListener('click', this.documentClick, false);
    }


    documentClick(e){
        this.setState({active : false});
        this.documentListerUnbind();
    }

    render() {
        const {
            icon = options,
            classes = '',
            children
        } = this.props;
      const {active} =  this.state;
        return (
            <div className={`drop-down-btn relative ${classes}`} >
                <IconButton classes="drop-down-icon-btn transparent dropper" icon={icon} clickHandler={this.onClickHandler.bind(this)} />
                <nav className={`drop-down-menu bg-white dropper ${active ? 'active' : ''}`}>
                    {children}
                </nav>
            </div>

        );
    }
}

export default DropdownButton;
